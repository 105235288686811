import React, { Component } from "react";
import { connect } from "react-redux";

//Components
import ContactFormFirstPage from "./contactFormFirstPage.component";
import ContactFormSuccessPage from "./contactFormSuccessPage.component";
import swal from "sweetalert2";

// Actions
import { createContact } from "../../actions/contact.action";

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
        };
        this.nextPage = this.nextPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    onSubmit() {
        this.props
            .createContact()
            .then((res) => {
                this.nextPage();
            })
            .catch((res) => {
                swal.fire("Enquiry Not Sent!", `${res.message}`, "error");
            });
    }

    render() {
        return (
            <>
                {this.state.page === 1 && (
                    <ContactFormFirstPage
                        onSubmit={this.onSubmit}
                        currentPage={this.state.page}
                        enableReinitialize={true}
                    />
                )}
                {this.state.page === 2 && (
                    <ContactFormSuccessPage currentPage={this.state.page} />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    form: state.form.contact,
});

export default connect(mapStateToProps, { createContact })(ContactForm);
