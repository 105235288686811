import { SUBSCRIBE_SUBMIT_FAILED, SUBSCRIBE_SUBMIT_SUCCESS } from "./types";

export const createSub = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let subscription = getState().form.subscribe.values;

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/subscribe/create`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(subscription)
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: SUBSCRIBE_SUBMIT_FAILED,
                        payload: post
                    });
                    reject(post);
                } else {
                    dispatch({
                        type: SUBSCRIBE_SUBMIT_SUCCESS,
                        payload: post
                    });
                    resolve(post);
                }
            });
    });
};
