import React, { Component } from "react";
import { connect } from "react-redux";

//Styles
import "../../styles/components/header/header.scss";
import "../../styles/pages/jobs.scss";

// Components
import Header from "../../components/header/header.component";
import JobResults from "../../components/job_results/jobResults.component";
import Footer from "../../components/footer/footer.component";

const header = {
    background: "jobBoard",
    searchType: "jobBoard",
    subHeading: "Start Your Job Search Here"
};


class JobBoard extends Component {
    render() {
        return (
            <div className="jobBoard-container">
                <Header header={header} type="board"  />
                <JobResults type="all"/>
                <Footer />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    jobs: state.jobs.jobs,
    form: state.form.search,
    currentJob: state.jobs.currentJob
});

export default connect(mapStateToProps, {})(JobBoard);
