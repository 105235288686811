import React from "react";

//Assets
import "../../styles/components/forms/contactFormv2.scss";

import { Field, reduxForm } from "redux-form";
import { dgInput, dgTextArea, phoneNumber, required, email } from "dg-forms";

let ContactForm = props => {
    return (
        <div className="form-container">
            <form className="contact-form" onSubmit={props.handleSubmit}>
                <div className="form-row">
                    <Field
                        name="your_details.name"
                        type="text"
                        placeholder=" "
                        component={dgInput}
                        label="Full Name"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="your_details.email"
                        type="text"
                        placeholder=" "
                        component={dgInput}
                        label="Email"
                        labelClass="required"
                        validate={[required, email]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="your_details.phone"
                        type="text"
                        placeholder=" "
                        component={dgInput}
                        label="Phone"
                        labelClass="required"
                        validate={[required, phoneNumber]}
                    />
                    <div className="form-row"></div>
                    <Field
                        name="your_details.message"
                        type="text"
                        placeholder=" "
                        component={dgTextArea}
                        containerClass="field-group message"
                        label="Message"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-container-button">
                    <button type="submit" className="btn-main">
                        SEND MESSAGE
                    </button>
                </div>
            </form>
        </div>
    );
};

ContactForm = reduxForm({
    // a unique name for the form
    form: "contact"
})(ContactForm);

export default ContactForm;
