import React from "react";

//Assets
import "../../styles/components/forms/contactFormv2.scss";

import { Field, reduxForm } from "redux-form";
import { dgInput, dgUpload, required, email } from "dg-forms";

let ApplicationForm = props => {
    const { handleSubmit, submitting, onSubmit, handleSelectedFile } = props;

    return (
        <div className="form-container">
            <div className="form-container-head">
                <ul>
                    <li>Job Board</li>
                    <li className="square">
                        <strong>Apply Now</strong>
                    </li>
                </ul>
                <h1>
                    {props.job && props.job.title && props.job.title !== ""
                        ? props.job.title
                        : ""}
                </h1>
            </div>
            <form
                className="contact-form application-form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="form-row">
                    <Field
                        name="your_details.name"
                        type="text"
                        placeholder=" "
                        component={dgInput}
                        label="Full Name"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="your_details.email"
                        type="text"
                        placeholder=" "
                        component={dgInput}
                        label="Email"
                        labelClass="required"
                        validate={[required, email]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        containerClass="field-group file"
                        multiple={true}
                        label="CV"
                        labelClass="required"
                        name="files"
                        onChange={handleSelectedFile}
                        accept={".docx, .pdf"}
                        component={dgUpload}
                    />
                </div>
                <div className="form-container-button">
                    <p>
                        Your data is protected. Please review our{" "}
                        <a href="/">Privacy Policy</a>
                    </p>
                    <button
                        type="submit"
                        className="btn-main"
                        disabled={submitting}
                    >
                        APPLY NOW
                    </button>
                </div>
            </form>
        </div>
    );
};

ApplicationForm = reduxForm({
    form: "application"
})(ApplicationForm);

export default ApplicationForm;
