import React, { Component } from "react";
import { connect } from "react-redux";

//Components
import FooterFormFirstPage from "./footerFormFirstPage.component";
import FooterFormSuccessPage from "./footerFormSuccessPage.component";
import swal from 'sweetalert2';


// Actions
import { createSub } from "../../actions/subscribe.action";

class SubscribeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1
        };
        this.nextPage = this.nextPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    onSubmit() {
        this.props
            .createSub()
            .then(res => {
                this.nextPage();
            })
            .catch(res => {
              swal.fire("Enquiry Not Sent!", `${res.message}`, "error");
            });
    }

    render() {
        return (
            <>
                {this.state.page === 1 && (
                    <FooterFormFirstPage
                        onSubmit={this.onSubmit}
                        currentPage={this.state.page}
                        enableReinitialize={true}
                    />
                )}
                {this.state.page === 2 && (
                    <FooterFormSuccessPage currentPage={this.state.page} />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    form: state.form.subscribe
});

export default connect(mapStateToProps, {createSub})(SubscribeForm);
