import { 
    FETCH_POSTS, 
    NEW_POST, 
    SELECT_POST, 
    GET_ALL_BLOGS, 
    CLEAR_POST_FORM,
    SET_UPLOADS
} from '../actions/types';

const initialState = {
    items: [],
    allBlogs: [],
    allItems: [],
    item: {},
    uploads: [],
    currentPost: {},
    clearForm: true
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_UPLOADS:
            return  {
                ...state,
                uploads: action.payload
            }
        case GET_ALL_BLOGS:
            return {
                ...state,
                ok: action.payload.ok,
                allBlogs: action.payload,
                message: action.payload.message
            };
        case FETCH_POSTS: 
            return {
                ...state,
                items: action.payload
            }
        case NEW_POST: 
            return {
                ...state,
                item: action.payload
            }
        case SELECT_POST: 
            return {
                ...state,
                currentPost: action.payload
            }
        case CLEAR_POST_FORM: 
            return {
                ...state,
                clearForm: action.payload
            }
        default:
            return state;
    }
}
