import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import history from "../../history";

// Actions
import { setFilter, PostPageAlt, getPostById, fetch, CardAlt, selectPost } from "redux-blogs";

import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

//Styles
import "../../styles/components/blog/post.scss";

//Assets
import headerLogo from "../../assets/webAssets/b.jpeg";

const header = {
    background: "blog",
    headingCentered: "WELCOME TO OUR WORLD",
    style: true,
};

class Post extends Component {
    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.props
            .fetch(4, 0, "")
            .then((data) => {
                console.log("blog post component, local: ", data);
            })
            .catch((data) => {
                console.log(data);
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            let urlParams = this.props.location.pathname;
            let params = urlParams.split("/");

            this.props
                .getPostById(params[3])
                .then((data) => {})
                .catch((data) => {
                    console.log(data);
                });
            window.scrollTo(0, 0);
        }
    }

    onSelect(post) {
        console.log("clicked");
        this.props.selectPost(post).then((data) => {
            window.scrollTo(0, 0);
            history.push(`/blog/post/${post._id}`);
        });
    }
    render() {
        return (
            <div className="post-container">
                <Header header={header} />
                <div className="column-container">
                    <div className="clamp container-row">
                        <div className="container-col-left">
                            <PostPageAlt
                                location={this.props.location}
                                post={this.props.posts.currentPost}
                                getPostById={this.props.getPostById}
                                defaultHeader={headerLogo}
                                noPostsComponent={
                                    <div>
                                        <h1>No Posts Found</h1>
                                    </div>
                                }
                                author_image={null}
                            />
                            <div className="button-container">
                                <NavLink to="/blog" exact={true}>
                                    <button className="btn-secondary">BACK TO ALL POSTS</button>
                                </NavLink>
                            </div>
                        </div>
                        <div className="container-col-right">
                            <div className="buttons">
                                <NavLink to="/blog" exact={true}>
                                    <button className="btn-secondary">BACK TO ALL POSTS</button>
                                </NavLink>
                            </div>
                            <div className="related-header">
                                <h3>RELATED ARTICLES</h3>
                            </div>

                            <div className="card-wrapper column">
                                {this.props.posts.items && this.props.posts.items.length > 0 ? (
                                    this.props.posts.items
                                        .filter((item) => item.status === "Published")
                                        .slice(0, 3)
                                        .map((item, index) =>
                                            this.props.currentPost._id !== item._id ? (
                                                <CardAlt
                                                    post={item}
                                                    cardClass={"card"}
                                                    onSelect={this.onSelect}
                                                    showDate={true}
                                                    showSummary={false}
                                                    fetchPosts={this.props.fetch}
                                                />
                                            ) : (
                                                ""
                                            )
                                        )
                                ) : (
                                    <h1>No Blog Posts Available</h1>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    posts: state.blogs,
    currentPost: state.blogs.currentPost,
});

export default connect(mapStateToProps, {
    setFilter,
    getPostById,
    fetch,
    selectPost,
})(Post);
