import {
    GET_JOBS_FAILED,
    SET_IS_JOB_LOADING,
    FETCH_JOB,
    SET_CURRENT_JOB,
    SET_SEARCH_VALUES,
    SET_TOTAL_ITEMS,
    SET_TOTAL_PAGES,
    SET_PAGE,
    GET_JOBS_SUCCESS,
    GET_ALL_JOBS
} from "../actions/types";

const initialState = {
    message: "",
    ok: true,
    error: [],
    jobs: [],
    totalItems: "",
    totalPages: "",
    page: "",
    allJobs: [],
    location: "",
    keyword: "",
    searchValues: {},
    currentJob: {},
    isJobLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case SET_TOTAL_PAGES:
            return {
                ...state,
                totalPages: action.payload
            };
        case SET_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload
            };
        case SET_CURRENT_JOB:
            return {
                ...state,
                currentJob: action.payload
            };
        case GET_ALL_JOBS:
            return {
                ...state,
                ok: action.payload.ok,
                allJobs: action.payload.jobs,
                message: action.payload.message
            };
        case GET_JOBS_SUCCESS:
            return {
                ...state,
                ok: action.payload.ok,
                jobs: action.payload.jobs,
                totalItems: action.payload.jobs.total_items,
                message: action.payload.message
            };
        case GET_JOBS_FAILED:
            return {
                ...state,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message
            };
        case SET_IS_JOB_LOADING:
            return {
                ...state,
                isJobLoading: action.payload
            };
        case FETCH_JOB:
            return {
                ...state,
                currentJob: action.payload.job,
                ok: action.payload.ok,
                errors: action.payload.errors,
                message: action.payload.message,
                isJobLoading: false
            };
        case SET_SEARCH_VALUES:
            return {
                ...state,
                searchValues: action.payload
            };
        default:
            return state;
    }
}
