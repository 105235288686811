import React, { Component } from "react";
import parse from "html-react-parser";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { MultiColumn } from "dg-blocks";

import Swal from "sweetalert2";

//Assets
import box1 from "../../assets/webAssets/job-box1.svg";
import arrow from "../../assets/icons/btn-arrow.svg";
import share from "../../assets/icons/share.svg";

//Styles
import "../../styles/components/job/job.scss";

//Actions
import { getJobById } from "../../actions/job.action";

class Job extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");
        console.log(params[2]);

        this.props
            .getJobById(params[2])
            .then((res) => {
                console.log(res);
            })
            .catch((res) => {
                Swal.fire("Failed", `${res.message}`, "error");
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            let urlParams = this.props.location.pathname;
            let params = urlParams.split("/");

            this.props
                .getJobById(params[2])
                .then((res) => {
                    console.log(res);
                })
                .catch((res) => {
                    Swal.fire("Failed", `${res.message}`, "error");
                });
            window.scrollTo(0, 0);
        }
    }

    render() {
        let items = [
            this.props.job &&
            this.props.job.job_details &&
            this.props.job.job_details.description &&
            this.props.job.job_details.description !== ""
                ? this.props.job.job_details.description.map((desc, index) => (
                      <div className="paragraph">
                          <p key={`${index}`}>{desc}</p>
                      </div>
                  ))
                : "",
        ];
        return (
            <div className="job-container clamp">
                <div className="job-header">
                    <div classname="job-date">
                        <p>
                            <strong>Posted:</strong>{" "}
                            {this.props.job &&
                            this.props.job.createdAt &&
                            this.props.job.createdAt !== ""
                                ? new Date(
                                      this.props.job.createdAt
                                  ).toLocaleDateString("en-gb")
                                : ""}
                        </p>
                    </div>
                    <div classname="job-title">
                        <h1>
                            {this.props.job.job_details &&
                            this.props.job.job_details.title &&
                            this.props.job.job_details.title !== ""
                                ? this.props.job.job_details.title
                                : ""}
                        </h1>
                        <div className="job-type">
                            {this.props.job.job_details &&
                            this.props.job.job_details.type &&
                            this.props.job.job_details.type !== ""
                                ? this.props.job.job_details.type
                                : ""}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col job-table">
                        <div className="left">
                            <div className="item">
                                <h2>LOCATION</h2>
                            </div>
                            <div className="item">
                                <h2>TYPE</h2>
                            </div>
                            <div className="item">
                                <h2>CATEGORY</h2>
                            </div>
                            <div className="item">
                                <h2>SALARY</h2>
                            </div>
                            <div className="item">
                                <h2>BENEFITS</h2>
                            </div>
                            <div className="item">
                                <h2>EXPERIENCE REQUIRED</h2>
                            </div>
                            <img
                                className="box-1"
                                src={box1}
                                alt="job details"
                            />
                        </div>
                        <div className="right">
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.location &&
                                this.props.job.job_details.location !== ""
                                    ? this.props.job.job_details.location
                                    : ""}
                            </div>
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.type &&
                                this.props.job.job_details.type !== ""
                                    ? this.props.job.job_details.type
                                    : ""}
                            </div>
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.function &&
                                this.props.job.job_details.function !== ""
                                    ? this.props.job.job_details.function
                                    : ""}
                            </div>
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.salary &&
                                this.props.job.job_details.salary !== ""
                                    ? `€${this.props.job.job_details.salary}`
                                    : ""}
                            </div>
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.benefits &&
                                this.props.job.job_details.benefits !== ""
                                    ? this.props.job.job_details.benefits.map(
                                          (benefit, index) => (
                                              <li
                                                  className="tick"
                                                  key={`${index}`}
                                              >
                                                  {benefit}
                                              </li>
                                          )
                                      )
                                    : ""}
                            </div>
                            <div className="item">
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.experience &&
                                this.props.job.job_details.experience !== ""
                                    ? this.props.job.job_details.experience
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="table-mobile">
                        <div className="table-row">
                            <div className="table-col">
                                <h3>LOCATION</h3>
                                <p>
                                    {this.props.job &&
                                    this.props.job.job_details &&
                                    this.props.job.job_details.location &&
                                    this.props.job.job_details.location !== ""
                                        ? this.props.job.job_details.location
                                        : ""}
                                </p>
                            </div>
                            <div className="table-col">
                                <h3>TYPE</h3>
                                <p>
                                    {this.props.job &&
                                    this.props.job.job_details &&
                                    this.props.job.job_details.type &&
                                    this.props.job.job_details.type !== ""
                                        ? this.props.job.job_details.type
                                        : ""}
                                </p>
                            </div>
                            <div className="table-col">
                                <h3>CATEGORY</h3>
                                <p>
                                    {this.props.job &&
                                    this.props.job.job_details &&
                                    this.props.job.job_details.function &&
                                    this.props.job.job_details.function !== ""
                                        ? this.props.job.job_details.function
                                        : ""}
                                </p>
                            </div>
                            <div className="table-col">
                                <h3>SALARY</h3>
                                <p>
                                    {this.props.job &&
                                    this.props.job.job_details &&
                                    this.props.job.job_details.salary &&
                                    this.props.job.job_details.salary !== ""
                                        ? `€${this.props.job.job_details.salary}`
                                        : ""}
                                </p>
                            </div>
                            <div className="table-col">
                                <h3>BENEFITS</h3>
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.benefits &&
                                this.props.job.job_details.benefits !== ""
                                    ? this.props.job.job_details.benefits.map(
                                          (benefit, index) => (
                                              <li
                                                  className="tick"
                                                  key={`${index}`}
                                              >
                                                  {benefit}
                                              </li>
                                          )
                                      )
                                    : ""}
                            </div>
                            <div className="table-col">
                                <h3>EXPERIENCE REQUIRED</h3>
                                {this.props.job &&
                                this.props.job.job_details &&
                                this.props.job.job_details.experience &&
                                this.props.job.job_details.experience !== ""
                                    ? this.props.job.job_details.experience
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apply-button-container">
                    <NavLink to={`/apply/${this.props.job._id}`} exact={true}>
                        <button className="btn-main">
                            APPLY NOW
                            <img src={arrow} alt="apply for job" />
                        </button>
                    </NavLink>
                    <a
                        href="https://www.facebook.com/sharer/sharer.php?u=example.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="btn-share">
                            <img src={share} alt="share job" />
                            Share Job Position{" "}
                        </button>
                    </a>
                </div>
                <div className="job-post-content">
                    {this.props.job &&
                    this.props.job.job_details &&
                    this.props.job.job_details.wysiwyg &&
                    this.props.job.job_details.wysiwyg !== ""
                        ? parse(this.props.job.job_details.wysiwyg)
                        : ""}
                </div>
                {this.props.job &&
                this.props.job.job_details &&
                this.props.job.job_details.description &&
                        this.props.job.job_details.description.length > 0 ? (
                    <MultiColumn
                        containerClass={"multiCol-container"}
                        headerClass={"multiCol-header"}
                        header={
                            <>
                                <h1>Job Description</h1>
                                <h4>
                                    Key responsibilities of this job will
                                    include:{" "}
                                </h4>
                            </>
                        }
                        posts={items}
                        rowClass={"multiCol-row"}
                        columnClass={"col"}
                        errorClass={"error-container"}
                        errorMessage={<h3>No Job Info Available</h3>}
                    />
                ) : (
                    ""
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    job: state.jobs.currentJob,
});

export default connect(mapStateToProps, { getJobById })(Job);
