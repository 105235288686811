import { NEW_POST } from "./types";
import { getAccessToken } from "./login.action";

export const createNewPost = data => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/create`, {
            method: "POST",
            headers: {
                access_token: token
            },
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: NEW_POST,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updatePost = (id, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/update/${id}`, {
            method: "POST",
            headers: {
                access_token: token
            },
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: NEW_POST,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};
