import React, { Component } from 'react';

import AdminPostList from '../../components/admin/posts/list.component';

class BlogManagement extends Component {
    
    render() {
        return (
            <div className="container">
                <main>
                    <AdminPostList />
                </main>
                
            </div>
        );
    }
}

export default BlogManagement;
