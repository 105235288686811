import {
    APPLICATION_SUBMIT,
    GET_APPLICATIONS
} from "../actions/types";

const initialState = {
    applications: [],
    currentApplication: {},
    message: "",
    ok: true,
    errors: [],
    isApplicationLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_APPLICATIONS: 
        return {
            ...state,
            ok: action.payload.ok,
            message: action.payload.message,
            errors: action.payload.errors,
            applications: action.payload.applications
        }
        case APPLICATION_SUBMIT:
            return {
                ...state,
                message: action.payload.message,
                ok: action.payload.ok,
                errors: action.payload.errors,
                currentApplication: action.payload.application,
                isApplicationLoading: false
            };
        default:
            return state;
    }
}
