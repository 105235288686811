import { 
    CONTACT_SUBMIT,
    DELETE_CONTACT,
    GET_CONTACTS
} from "./types";

import { getAccessToken } from "../actions/login.action";
export const createContact = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let contact = getState().form.contact.values;

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/create`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(contact)
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CONTACT_SUBMIT,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const deleteContact = contactId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/contacts/delete/${contactId}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_CONTACT,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getContacts = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/?page=${page}&page_size=${page_size}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: GET_CONTACTS,
                    payload: post
                });
                
                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getContactById = (contactId) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/get/${contactId}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CONTACT_SUBMIT,
                    payload: post
                });
                
                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};
