import { CREATE_USER, DELETE_USER, GET_USERS } from "../actions/types";

const initialState = {
    users: [],
    currentUser: {},
    message: "",
    ok: true,
    errors: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                users: action.payload.users,
            };
        case CREATE_USER:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentUser: action.payload.user,
            };
        case DELETE_USER:
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors,
                currentUser: action.payload.user,
            };
        default:
            return state;
    }
}
