import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Pagination } from "dg-pagination";
import Swal from "sweetalert2";

//Styles
import "../../styles/components/job_results/jobResults.scss";

//Actions
import {
    getJobs,
    getJobByCategory,
    setTotalItems,
    setTotalPages,
    setPage,
    createJobSearch,
} from "../../actions/job.action";

class JobResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 8,
            total_pages: 0,
            total_items: 0,
            noSimilarJobs: false,
        };

        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        window.scrollTo({
            behavious: "smooth",
            top: 500,
        });

        if (this.props.type === "all") {
            if (!this.props.searchValues.isEmpty) {
                this.props
                    .createJobSearch(page, page_size, this.props.searchValues)
                    .then((res) => {
                        this.setState({
                            page: res.page,
                            page_size: res.page_size,
                            total_pages: res.total_pages,
                            total_items: res.total_items,
                        });
                        this.props.setTotalItems(this.state.total_items);
                        this.props.setTotalPages(this.state.total_pages);
                        this.props.setPage(this.state.page);
                    })
                    .catch((res) => {
                        Swal.fire("Failed", `${res.message}`, "error");
                    });
            } else {
                this.props
                    .getJobs(page, page_size)
                    .then((res) => {
                        this.setState({
                            page: res.page,
                            page_size: res.page_size,
                            total_pages: res.total_pages,
                            total_items: res.total_items,
                        });
                        this.props.setTotalItems(this.state.total_items);
                        this.props.setTotalPages(this.state.total_pages);
                        this.props.setPage(this.state.page);
                    })
                    .catch((res) => {
                        Swal.fire(`Error`, res.message, "error");
                    });
            }
            //Individual job page. Search for similar jobs
        } else if (this.props.type === "category") {
            console.log("prop type: ", this.props.category);
            this.props
                .getJobByCategory(this.props.category)
                .then((res) => {
                    if (res.total_items <= 1) {
                        this.setState({
                            noSimilarJobs: true,
                            page: res.page,
                            page_size: res.page_size,
                            total_pages: res.total_pages,
                            total_items: res.total_items,
                        });
                    }
                })
                .catch((res) => {
                    alert("Failed", res.message, "error");
                });
        } else {
            this.props
                .getJobs(page, page_size)
                .then((res) => {
                    this.setState({
                        page: res.page,
                        page_size: res.page_size,
                        total_pages: res.total_pages,
                        total_items: res.total_items,
                    });
                    this.props.setTotalItems(this.state.total_items);
                    this.props.setTotalPages(this.state.total_pages);
                    this.props.setPage(this.state.page);
                })
                .catch((res) => {
                    Swal.fire(`Error`, res.message, "error");
                });
        }
    }

    render() {
        return !this.state.noSimilarJobs ? (
            <div className="results-container clamp">
                {this.props.header ? (
                    <div className="col-table-heading">RELATED VACANCIES</div>
                ) : (
                    ""
                )}
                <table className="container-table">
                    {this.props.jobs.map((job, index) =>
                        job._id === this.props.currentJob._id ? (
                            ""
                        ) : (
                            <tbody
                                key={`${index}`}
                                className="container-row-table"
                            >
                                <tr className="info-col">
                                    <td className="table-col">
                                        <h3>JOB TITLE</h3>
                                        <p>
                                            <strong>
                                                {job.job_details.title}
                                            </strong>
                                        </p>
                                    </td>
                                    <td className="table-col">
                                        <h3>JOB FUNCTION</h3>
                                        <p>{job.job_details.function} </p>
                                    </td>
                                    <td className="table-col">
                                        <h3>LOCATION</h3>
                                        <p>{job.job_details.location}</p>
                                    </td>
                                    <td className="table-col">
                                        <h3>SALARY</h3>
                                        <p>€{job.job_details.salary}</p>
                                    </td>
                                    <td className="table-col">
                                        <h3>JOB TYPE</h3>
                                        <p>{job.job_details.type}</p>
                                    </td>
                                    <td className="table-col-button">
                                        <NavLink
                                            to={`/job/${job._id}`}
                                            exact={true}
                                        >
                                            <button
                                                onClick={this.handleClick}
                                                className="btn-secondary"
                                            >
                                                VIEW MORE
                                            </button>
                                        </NavLink>
                                        <NavLink
                                            to={`/apply/${job._id}`}
                                            exact={true}
                                        >
                                            <button className="btn-main">
                                                APPLY NOW
                                            </button>
                                        </NavLink>
                                    </td>
                                </tr>
                                <tr className="description-col">
                                    <td className="table-col-description">
                                        <p>{job.job_details.summary} </p>
                                    </td>
                                </tr>
                            </tbody>
                        )
                    )}
                </table>
                {this.props.totals.totalPages > 1 ? (
                    <Pagination
                        type="Standard"
                        paginate={this.paginate}
                        page_size={this.state.page_size}
                        page={this.props.totals.page}
                        total_pages={this.props.totals.totalPages}
                        total_items={this.props.totals.totalItems}
                    />
                ) : (
                    ""
                )}
            </div>
        ) : (
            ""
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.jobs.jobs,
    totals: state.jobs,
    searchValues: state.jobs.searchValues,
    currentJob: state.jobs.currentJob,
});

export default connect(mapStateToProps, {
    getJobs,
    getJobByCategory,
    setTotalItems,
    setTotalPages,
    setPage,
    createJobSearch,
})(JobResults);
