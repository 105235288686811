import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import swal from "sweetalert2";

//Assets
import jobEmblem from "../../assets/icons/jobEmblem.svg";
import leaf from "../../assets/webAssets/fern.svg";
import arrow from "../../assets/icons/btn-arrow.svg";

//Styles
import "../../styles/components/featured_jobs/featuredJobs.scss";

//Actions
import { getJobs } from "../../actions/job.action";

class FeaturedJobs extends Component {
    componentDidMount() {
        this.props
            .getJobs()
            .then(res => {
                console.log(res);
            })
            .catch(res => {
                swal.fire("Failed", `${res.message}`, "error");
            });
    }
    render() {
        return (
            <div className="featuredJobs-container clamp">
                <div className="heading-wrapper">
                    <div className="heading-container">
                        <hr />
                        <h1>FEATURED JOBS</h1>
                        <img src={leaf} alt="leaf background" />
                    </div>
                </div>
                {this.props.jobs && this.props.jobs.length > 0 ? (
                    <div className="featuredJobs-row">
                        <div className="container-col-table column">
                            <h2 className="heading">RECENT JOBS</h2>
                            <table>
                                {this.props.jobs
                                    .slice(0, 5)
                                    .map((job, index) => (
                                        <div className="table-row">
                                            <tr
                                                key={`${index}`}
                                                className="table-col"
                                            >
                                                <td>
                                                    <h3>
                                                        {job.job_details.title}
                                                    </h3>
                                                </td>
                                                <td>
                                                    {job.job_details.location}
                                                </td>
                                            </tr>
                                            <tr className="table-col">
                                                <td>
                                                    €{job.job_details.salary}
                                                </td>
                                                <td>
                                                    <h3>
                                                        {job.job_details.type}
                                                    </h3>
                                                </td>
                                            </tr>
                                            <tr className="table-col">
                                                <NavLink
                                                    to={`/job/${job._id}`}
                                                    exact={true}
                                                >
                                                    <button className="btn-secondary">
                                                        VIEW MORE{" "}
                                                    </button>
                                                </NavLink>
                                            </tr>
                                            <div className="breakLine"></div>
                                        </div>
                                    ))}
                            </table>
                        </div>
                        <div className="container-col-card column">
                            <h2 className="heading">MOST POPULAR</h2>
                            {console.log(this.props.jobs[0])}
                            {this.props.jobs[0] &&
                            this.props.jobs[0].files &&
                            this.props.jobs[0].files !== ""
                                ? this.props.jobs[0].files
                                      .slice(0)
                                      .map((file, index) => (
                                          <div className="job-image">
                                              <img
                                                  alt="Attached job"
                                                  src={file.url}
                                              />
                                          </div>
                                      ))
                                : ""}

                            <div className="card-wrapper">
                                <div className="card-header">
                                    <h2>
                                        {this.props.jobs[0] &&
                                        this.props.jobs[0].job_details &&
                                        this.props.jobs[0].job_details.title &&
                                        this.props.jobs[0].job_details.title !==
                                            ""
                                            ? this.props.jobs[0].job_details
                                                  .title
                                            : ""}
                                    </h2>
                                    <img src={jobEmblem} alt="job emblem" />
                                    <h5>
                                        {this.props.jobs[0] &&
                                        this.props.jobs[0].job_details &&
                                        this.props.jobs[0].job_details
                                            .location &&
                                        this.props.jobs[0].job_details
                                            .location !== ""
                                            ? this.props.jobs[0].job_details
                                                  .location
                                            : ""}
                                    </h5>
                                </div>
                                <div className="card-info">
                                    <p>
                                        <strong>
                                            {this.props.jobs[0] &&
                                            this.props.jobs[0].job_details &&
                                            this.props.jobs[0].job_details
                                                .type &&
                                            this.props.jobs[0].job_details
                                                .type !== ""
                                                ? this.props.jobs[0].job_details
                                                      .type
                                                : ""}
                                        </strong>{" "}
                                    </p>
                                    <p>
                                        €
                                        {this.props.jobs[0] &&
                                        this.props.jobs[0].job_details &&
                                        this.props.jobs[0].job_details.salary &&
                                        this.props.jobs[0].job_details
                                            .salary !== ""
                                            ? this.props.jobs[0].job_details
                                                  .salary
                                            : ""}
                                    </p>
                                </div>
                                <div className="card-main">
                                    <p>
                                        {this.props.jobs[0] &&
                                        this.props.jobs[0].job_details &&
                                        this.props.jobs[0].job_details
                                            .summary &&
                                        this.props.jobs[0].job_details
                                            .summary !== ""
                                            ? this.props.jobs[0].job_details
                                                  .summary
                                            : ""}
                                    </p>
                                    <NavLink to="/job-board" exact={true}>
                                        <button className="btn-main">
                                            VIEW MORE
                                            <img
                                                src={arrow}
                                                alt="more about us"
                                            />
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="error-message">
                        <h1>We're Sorry!</h1>
                        <p>There doesn't seem to be any jobs at the moment.</p>
                    </div>
                )}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    jobs: state.jobs.jobs
});

export default connect(mapStateToProps, { getJobs })(FeaturedJobs);
