import React, { Component } from "react";
import { connect } from "react-redux";
import { DgCustomForm } from "dg-forms";
import history from "../../../history";
import { Field } from "redux-form";
import { dgSelect, dgUpload, dgInput, required } from "dg-forms";
import WYSIWUG_Editor from "../wysiwug/WYSIWUG_Editor";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/jobForm.scss";

// Actions
import { createJob } from "../../../actions/job.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class CreateJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
    }
    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files,
        });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }
        form.append("job_details", JSON.stringify(data));
        this.props
            .createJob(form)
            .then((res) => {
                Swal.fire(`Job Created`, res.message, "success");
                history.push("/admin/jobs");
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }
    render() {
        let fields = [
            <>
                <div className="form-row">
                    <Field
                        name="job_details.title"
                        type="text"
                        component={dgInput}
                        label="Job Title"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.type"
                        labelClass="required"
                        label="Type"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select
                        </option>
                        <option value="Permanent" selected>
                            Permanent
                        </option>
                        <option value="Temporary" selected>
                            Temporary
                        </option>
                        <option value="Contract" selected>
                            Contract
                        </option>
                    </Field>
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.location"
                        type="text"
                        component={dgInput}
                        label="Location"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.function"
                        type="text"
                        component={dgInput}
                        label="Job Category"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row"></div>
                <div className="form-row column">
                    <label name="benefits">Job Benefits</label>
                    <Field
                        name="job_details.benefits[0]"
                        type="text"
                        component={dgInput}
                        placeholder="Benefit 1"
                    />
                    <Field
                        name="benefits[1]"
                        type="text"
                        component={dgInput}
                        placeholder="Benefit 2"
                    />
                    <Field
                        name="benefits[2]"
                        placeholder="Benefit 3"
                        type="text"
                        component={dgInput}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.experience"
                        type="text"
                        component={dgInput}
                        label="Experience"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.salary"
                        type="text"
                        component={dgInput}
                        label="Salary"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        containerClass="field-group"
                        multiple={false}
                        label="Job Image"
                        labelClass="required"
                        name="files"
                        onChange={this.handleSelectedFile}
                        accept={".png, .jpg, .jpeg"}
                        component={dgUpload}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.summary"
                        type="text"
                        component={dgInput}
                        label="Summary"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row column">
                    <label name="description">Job Description</label>

                    <Field
                        name="job_details.wysiwyg"
                        component={WYSIWUG_Editor}
                    />
                </div>
            </>,
        ];
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="create-form-container">
                        <DgCustomForm
                            innerContainerClass=""
                            headerContainerClass="header"
                            formHeader={<h1>Create Job</h1>}
                            buttonContainerClass="button-container"
                            fields={fields}
                            submitText="Create Job"
                            submitClass="btn-main"
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.jobs,
});

export default connect(mapStateToProps, {
    createJob,
})(CreateJob);
