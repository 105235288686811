import React, { Component } from "react";

// Styles
import "../../styles/pages/policy.scss";

// Components
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

const header = {
    background: "about",
    headingCentered: "WEB POLICY",
    style: true,
};

class Policy extends Component {
    render() {
        return (
            <div className="Legal">
                <Header header={header} />

                <div className="container">
                    <div className="header">
                        <h2>Bracken Recruitment Privacy Policy</h2>
                    </div>
                    <div className="item">
                        <p>Your privacy is very important to us. At Bracken Recruitment Ltd, we have a few fundamental guiding principles: </p>
                        <ul>
                            <li>We do not ask for your personal information unless we truly need it.  </li>
                            <li>We do not share your personal information with anyone except to potential employers, (with your knowledge and permission), to comply with the law, develop our products or protect our rights. </li>
                            <li>We do not store personal information on our servers, unless required for the on-going operation of one of our services and delete it once it is no longer useful.  </li>
                        </ul>
                        <p>The General Data Protection Regulation (GDPR) is designed to protect individuals and their personal data. We have committed to adhere to the relevant principles and guidelines outlined by the General Data Protection Regulation (GDPR), which was effective from 25th May 2018. </p>
                        <p>As part of the GDPR, we are required to obtain written consent for all data we collect and have collected, to enable the carrying out of project engagements and recruitment processes. </p>
                        <p>Please review this policy before agreeing that we can process your data. </p>
                        
                        <h3>What Types of Data Do We Collect?</h3>
                        <h4>1. Non-Personal Data </h4>
                        <p>Like most website operators, Bracken Recruitment collects Non-Personal Data from its website which cannot be used to identify or contact you. This type of data includes:  </p>
                        <ul>
                            <li>Statistical information such as date, time, language preference, referring site </li>
                            <li>Demographic information </li>
                            <li>Browser types </li>
                        </ul>
                        <p>Bracken Recruitment uses this type of data to better understand how visitors use their website, to help improve usability, identify trends and generate reports. </p>

                        <h4>2. Personal data</h4>
                        <p>Personal data relates to a specific individual who may be identifiable from that data. Bracken Recruitment requires this type of data to carry out its business with you: </p>
                        <h5>Contact information</h5>
                        <ul>
                            <li>Any 3rd party references </li>
                            <li>User IP addresses</li>
                            <li>Notes from meetings/interviews/phone calls with you </li>
                            <li>Your work experience and qualifications </li>
                            <li>Any other data related to possible work placements </li>
                        </ul>

                        <h5>Why do we store your personal data? </h5>
                        <p>Bracken Recruitment is a recruitment agency. Your personal data will only be collected by Bracken Recruitment to provide you with recruitment services, send occasional data on updates, new features, request feedback. </p>

                        <h5>How will your data be collected? </h5>
                        <p>Bracken Recruitment collects personal data directly from you when you: </p>
                        <ul>
                            <li>Provide us with information when you deal with us by phone, letter, email or web</li>
                            <li>Supply us with a CV or complete an application form </li>
                            <li>Submit any further information connected with an application </li>
                            <li>Store contact information when you subscribe to newsletters and updates via email </li>
                        </ul>

                        <p>If collecting information from a 3rd party, we will firstly seek your consent or otherwise make you aware of the collection and the reasons for it. This may include:  </p>
                        <ul>
                            <li>Professional references </li>
                            <li>Results of competency tests </li>
                            <li>Subjective feedback on performance</li>
                            <li>Information required by Clients e.g. Garda vetting. </li>
                        </ul>

                        <h5>How is your data stored? </h5>
                        <p>Bracken Recruitment store your data in our Recruitment Database (currently Bullhorn CRM). Bullhorn is a cloud-based infrastructure which is compliant with standards for IT and cloud security (ISO 27001, and SOC 2). </p>
                        <p>Your data is accessible only to relevant members of our in-house team and is only searched and processed in order to provide recruitment services. We will not save your data unless you clearly agree to our Data Privacy policy.  We retain this as a record of who has agreed to our policy. This is then stored on our database. </p>
                        <p>Where a marketing platform or email marketing service is used, Bracken Recruitment commits to using an SOC 2 and PCI DSS compliant form (currently Mailchimp). For e-mail correspondence, Bracken Recruitment also commits to using a secure hosting provider (currently Digigrow Ltd).   </p>

                        <h5>How do we use your data? </h5>
                        <p>Bracken Recruitment may use and disclose your personal data for the following reasons: </p>
                        <ul>
                            <li>to provide recruitment and related services to clients who have requested this service </li>
                            <li>to allow you to submit your CV, apply for specific jobs or to subscribe to job alerts from associates of Bracken Recruitment. </li>
                            <li>to provide marketing communications with your consent.  </li>
                            <li>
                                <p>Bracken Recruitment may also disclose your personal data to third parties where relevant: </p>
                                <ul>
                                    <li>prospective employers and parties such as referees </li>
                                    <li>other parties involved in the supply of personnel </li>
                                    <li>professional associations, registration bodies or regulatory or law enforcement agencies if required </li>
                                </ul>
                            </li>
                        </ul>

                        <p>Unless required to do so by law, Bracken Recruitment will not otherwise share, sell or distribute any of your personal data without your consent. </p>
                    
                        <h5>Your security, our priority </h5>
                        <p>Our security procedures protect data from being misused, misplaced, or accessed, changed or disclosed by unauthorised people. </p>
                        <p>Your data can only be accessed by authorised persons in order to provide you with recruitment and recruitment-related services. </p>
                        <p>We will retain data on our database only for as long as necessary. As a recruitment services provider, we aim to provide you with a quality ongoing recruitment service and relationship. We maintain specific records management and retention policies and procedures, so that personal data is deleted after a reasonable time that has been deemed to provide you with ongoing recruitment services and maintain a relationship (6 years) or according to the following retention criteria: </p>
                        <ul>
                            <li>We retain your data as long as we have an ongoing relationship with you. </li>
                            <li>We will only keep the data while your consultation is active or for as long as needed to provide services to you. </li>
                            <li>We retain your data for as long as needed in order to comply with our legal and contractual obligations. </li>
                        </ul>
                        <p>However, you can request removal of your data at any time. </p>

                        <h5>When to get in touch with us </h5>
                        <p>To further ensure the integrity of your data, you can get in touch with us at any time for any of the following reasons: </p>
                        
                        <h6>1. Requesting access to your data </h6>
                        <p>You may request access to any personal data Bracken Recruitment holds about you. </p>

                        <h6>2. Keeping your personal data up to date </h6>
                        <p>To ensure that your personal data is accurate, complete and up to date, please alert Bracken Recruitment if any of your details change. Alternatively, we may contact you from time to time. If you have any concerns about the accuracy of the data get in touch. </p>

                        <h6>3. To request to transfer your data </h6>
                        <p>You may wish for your data to be transferred to another data controller of your choice, in which case we will either pass this information to you or to the controller of your choice. </p>

                        <h6>To request removal of your data from our database </h6>
                        <p>You may get in touch with us at any time to request removal at any time. </p>
                        <p>For more information on any of the above matters or other queries related to your data, please contact us – see below. </p>

                        <h5>How to get in touch </h5>
                        <p>In order to get in touch with any query in relation to your data, simply send a mail to info@brackenrecruitment.ie outlining the nature of your request. We will respond promptly to your query. You may be asked for further details in relation to your request, or to fill out our Data Access Request Form. </p>

                        <h5>Transferring within the EEA </h5>
                        <p>Bracken Recruitment may transfer data to other companies within the EEA to be used in relation to recruitment or related services provided by Bracken Recruitment. </p>

                        <h5>Transferring out of the EEA </h5>
                        <p>Currently, Bracken Recruitment only transfer data within the EEA. If in future, we need to transfer your data outside the EEA, we will alert you and explain clearly why we’re doing it. This will only occur in relation to recruitment or related services provided by Bracken Recruitment.  </p>

                        <h5>Website security </h5>
                        <p>Our website may issue cookies to your computer. Most browsers allow cookies to be turned off but doing so may restrict use of our site. </p>
                        <p>If linked by Bracken Recruitment to another site, Bracken Recruitment cannot accept responsibility for differing data privacy practices. </p>

                        <h5>Changes to our Privacy Policy </h5>
                        <p>We keep our privacy statement under regular review, and we will place any updates on this web page. The privacy statement was last updated on 9th March 2021. </p>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
export default Policy;
