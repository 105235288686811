import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

//Components
import ApplicationFormFirstPage from "./applicationFormFirstPage.component";
import ApplicationFormSuccessPage from "./applicationFormSuccessPage.component";

// Actions
import { createApplication } from "../../actions/application.action";
import { getJobById } from "../../actions/job.action";

class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            selectedFile: null
        };
        this.handleSelectedFile = this.handleSelectedFile.bind(this);
        this.handleUpload = this.nextPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");
        console.log(params[2]);

        this.props
            .getJobById(params[2])
            .then(res => {
                console.log(res);
            })
            .catch(res => {
                Swal.fire("Failed", `${res.message}`, "error");
            });
    }

    handleSelectedFile(e) {
        e.preventDefault();

        this.setState({
            selectedFile: e.target.files
        });
        Swal.fire("CV Uploaded Successfully", ``, "success");
    }

    nextPage() {
        this.setState({ page: this.state.page + 1 });
    }

    onSubmit(data) {
        const form = new FormData();

        if (this.state.selectedFile && this.state.selectedFile.length > 0) {
            for (
                let index = 0;
                index < this.state.selectedFile.length;
                index++
            ) {
                form.append("files", this.state.selectedFile[index]);
            }
        }

        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 2) {
            data.job = params[2];
            form.append("your_details", JSON.stringify(data));

            this.props
                .createApplication(form)
                .then(res => {
                    this.nextPage();
                })
                .catch(res => {
                    Swal.fire("Upload Failed!", res.message, "error");
                });
        } else {
            Swal.fire(
                "Ooops!",
                `Couldn't match your application to a job, please try refreshing the page and trying again.`,
                "error"
            );
        }
    }

    render() {
        return (
            <>
                {this.state.page === 1 && (
                    <ApplicationFormFirstPage
                        onSubmit={this.onSubmit}
                        currentPage={this.state.page}
                        handleSelectedFile={this.handleSelectedFile}
                        handleUpload={this.handleUpload}
                        job={this.props.job}
                        onChange={this.onChange}
                    />
                )}
                {this.state.page === 2 && (
                    <ApplicationFormSuccessPage currentPage={this.state.page} />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    form: state.form.application,
    job: state.jobs.currentJob
});

export default connect(mapStateToProps, { createApplication, getJobById })(
    ApplicationForm
);
