import React from "react";
import { Field, reduxForm } from "redux-form";
import { dgInput, required, email } from "dg-forms";

const LoginForm = props => {
    const { handleSubmit, pristine, submitting } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="header">
                <h1>Enter Your Email & Password</h1>
            </div>
            <Field
                name="email"
                type="email"
                component={dgInput}
                containerClass="field-group"
                inputContainerClass="form-input-container"
                label="Email address"
                labelClass="required"
                validate={[required, email]}
            />
            <Field
                name="password"
                type="password"
                component={dgInput}
                label="Password"
                labelClass="required"
                validate={[required]}
            />
            <div className="button-container">
                <button
                    type="submit"
                    className="btn-main"
                    disabled={pristine || submitting}
                >
                    Login
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "LoginForm" // a unique identifier for this form
})(LoginForm);
