import {
    APPLICATION_SUBMIT,
    DELETE_APPLICATION,
    GET_APPLICATIONS
} from "./types";
import { getAccessToken } from "../actions/login.action";

export const createApplication = (data, id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/apply/create`, {
            method: "POST",
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: APPLICATION_SUBMIT,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getApplications = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/apply/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: GET_APPLICATIONS,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const deleteApplication = applicationId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/apply/delete/${applicationId}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_APPLICATION,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const getApplicationById = applicationId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/apply/get/${applicationId}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: APPLICATION_SUBMIT,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};
