import React, { Component } from "react";
import { connect } from "react-redux";

//Styles
import "../../styles/components/header/header.scss";
import "../../styles/pages/home.scss";

//Assets
import permanent from "../../assets/webAssets/permanent.svg";
import contract from "../../assets/webAssets/contract.svg";
import temporary from "../../assets/webAssets/temporary.svg";
import agency from "../../assets/webAssets/agency.svg";
import payroll from "../../assets/webAssets/payroll.svg";
import backgroundImage from "../../assets/webAssets/homeHeader.svg";

// Components
import Header from "../../components/header/header.component";
import FeaturedJobs from "../../components/featured_jobs/featuredJobs.component";
import Services from "../../components/services/services.component";
import Submit from "../../components/submit/submit.component";
//import Reviews from "../../components/reviews/reviews.component";
import Blog from "../../components/blog/blogLatest.component";
import Footer from "../../components/footer/footer.component";

const header = {
    background: "home",
    searchType: "jobBoard",
    heading: "Finding The Perfect Fit",
    subHeading: "Start Your Job Search Here",
};

const servicesTop = [
    {
        image: permanent,
        description:
            "Our contingent recruitment services are based on a no placement no fee basis. You will work with an expert recruiter who will find the right fit for your long term employment needs",
        label: "PERMANENT",
    },
    {
        image: contract,
        description:
            "Our expert team will work closely with you to find contract staff specific to your sector as and when your need arises",
        label: "CONTRACT",
    },
    {
        image: temporary,
        description:
            "Fast turnaround times are vital for any temporary employment requirement our experts will respond to your specific requirement whether unexpected or planned",
        label: "TEMPORARY",
    },
];
const servicesBottom = [
    {
        image: agency,
        description:
            "Bracken’s payroll services are ideal for small businesses and startups who have their own staff but do not require an on-site accountant",
        label: "PAYROLL SERVICES",
    },
    {
        image: payroll,
        description:
            "The Employer Hub allows employers to register vacancies today so we can find their talent for tomorrow",
        label: "EMPLOYER HUB: +",
    },
];

class Home extends Component {
    render() {
        return (
            <div className="home-container">
                <Header
                    backgroundImage={backgroundImage}
                    header={header}
                    type="home"
                />
                <div className="home-container column-container">
                    <FeaturedJobs />
                    <Services
                        servicesTop={servicesTop}
                        servicesBottom={servicesBottom}
                    />
                    <Submit />
                        {/*<Reviews />*/}
                    <Blog />
                </div>
                <Footer />
            </div>
        );
    }
}
export default connect(null, {})(Home);
