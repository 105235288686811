import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/view.scss";

// Actions
import { getApplicationById } from "../../../actions/application.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class ViewApplication extends Component {
    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props.getApplicationById(params[3]).catch(res => {
                Swal.fire("Error", res.message, "error");
            });
        }
    }

    render() {
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="view-container">
                        <div className="item">
                            <h1>Application Details</h1>
                        </div>
                        <div className="card">
                            <div className="card-top">
                                <h2>
                                    <strong>Applicant Details:</strong>
                                </h2>{" "}
                            </div>
                            <div className="card-bottom header">
                                <div className="col">
                                    <h3>
                                        <strong>Name:</strong>{" "}
                                        {this.props.applications
                                            .currentApplication &&
                                        this.props.applications
                                            .currentApplication.your_details &&
                                        this.props.applications
                                            .currentApplication.your_details
                                            .name &&
                                        this.props.applications
                                            .currentApplication.your_details
                                            .name !== ""
                                            ? this.props.applications
                                                  .currentApplication
                                                  .your_details.name
                                            : ""}
                                    </h3>
                                    <h3>
                                        <strong>Created On:</strong>{" "}
                                        {this.props.applications
                                            .currentApplication &&
                                        this.props.applications
                                            .currentApplication.createdAt &&
                                        this.props.applications
                                            .currentApplication.createdAt !== ""
                                            ? new Date(
                                                  this.props.applications.currentApplication.createdAt
                                              ).toLocaleDateString()
                                            : ""}
                                    </h3>
                                </div>
                                <div className="col">
                                    <h3>
                                        <strong>Email:</strong>{" "}
                                        {this.props.applications
                                            .currentApplication &&
                                        this.props.applications
                                            .currentApplication.your_details &&
                                        this.props.applications
                                            .currentApplication.your_details
                                            .email &&
                                        this.props.applications
                                            .currentApplication.your_details
                                            .email !== ""
                                            ? this.props.applications
                                                  .currentApplication
                                                  .your_details.email
                                            : ""}
                                    </h3>
                                    {this.props.applications &&
                                    this.props.applications
                                        .currentApplication &&
                                    this.props.applications.currentApplication
                                        .files &&
                                    this.props.applications.currentApplication
                                        .files.files !== ""
                                        ? this.props.applications.currentApplication.files
                                              .slice(0)
                                              .map((file, index) => (
                                                  <a
                                                      href={file.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                  >
                                                      <button className="btn-main">
                                                          View CV
                                                      </button>
                                                  </a>
                                              ))
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    applications: state.applications
});

export default connect(mapStateToProps, {
    getApplicationById
})(ViewApplication);
