import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../../history";
import { Pagination } from "dg-pagination";

// Styles
import "../../../styles/components/admin/list.scss";
import "../../../styles/components/admin/admin.scss";

// Actions
import {
    deleteApplication,
    getApplications
} from "../../../actions/application.action";

// Components
// import ListOptions from './listOptions';
import Sidebar from "../sidebar/sidebar.admin.component";
import ListOptions from "./listOptions";

class ApplicationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 10,
            total_pages: 0,
            total_items: 0
        };

        this.onDelete = this.onDelete.bind(this);
        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }
    onDelete(event, value) {
        event.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete application"
        }).then(result => {
            if (result.isConfirmed) {
                this.props.deleteApplication(value._id);
                Swal.fire(
                    "Deleted!",
                    "Application has been deleted.",
                    "success"
                );
                this.componentDidMount();
            }
        });
    }

    paginate(page, page_size) {
        this.props
            .getApplications(page, page_size)
            .then(res => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items
                });
            })
            .catch(res => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let rows = [];

        if (
            this.props.applications &&
            this.props.applications.applications &&
            this.props.applications.applications.length > 0
        ) {
            rows = this.props.applications.applications.map((value, index) => (
                <tr>
                    <td
                        onClick={() =>
                            history.push(`/admin/application/${value._id}`)
                        }
                    >
                        {value.your_details.name}
                    </td>
                    <td
                        onClick={() =>
                            history.push(`/admin/application/${value._id}`)
                        }
                    >
                        {value.your_details.email}
                    </td>
                    <td
                        onClick={() =>
                            history.push(`/admin/application/${value._id}`)
                        }
                    >
                        {value.job &&
                        value.job.job_details &&
                        value.job.job_details.title &&
                        value.job.job_details.title !== ""
                            ? value.job.job_details.title
                            : ""}
                    </td>
                    <td>
                        <ListOptions onDelete={e => this.onDelete(e, value)} />
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="4">No Applications available ...</td>
                </tr>
            ];
        }

        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="item head">
                            <h1>Applications</h1>
                        </div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Job Title</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    applications: state.applications
});

export default connect(mapStateToProps, {
    deleteApplication,
    getApplications
})(ApplicationList);
