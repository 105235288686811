import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";

//Components
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";

//Styles
import "../../styles/components/header/header.scss";
import "../../styles/components/blog/posts.scss";
import "../../styles/pages/blogList.scss";

// Actions
import {
    getAllTags,
    fetch,
    BlogsPage,
    setFilter,
    selectPost,
} from "redux-blogs";

const header = {
    background: "blog",
    headingCentered: "WELCOME TO OUR WORLD",
    style: true,
};

class Posts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page_size: 6,
        };

        this.onSelect = this.onSelect.bind(this);
    }
    componentDidMount() {
        this.props.selectPost({})
        this.props.setFilter({ status: "Published" }).then((data) => {
            this.props
                .fetch(this.state.page_size, 0, this.props.posts.filter)
                .then((data) => {
                    console.log(data);
                })
                .catch((data) => {
                    console.log(data);
                    alert("Failed");
                });
        });
    }

    onSelect(post) {
        this.props.selectPost(post).then((data) => {
            window.scrollTo(0, 0);
            history.push(`/blog/post/${post._id}`);
        });
    }

    render() {
        return (
            <div className="blogList-container">
                <Header header={header} />
                <div className="clamp column-container">
                    <BlogsPage
                        containerStyle={{}}
                        containerClass={""}
                        postsContainerStyle={{}}
                        postsContainerClass={"post"}
                        containerMiddleClass={"container-col"}
                        postStyle={{}}
                        postClass={""}
                        cardType="alt"
                        showDate={true}
                        showSummary={true}
                        posts={this.props.posts.items}
                        post={this.props.posts}
                        postOnSelect={this.onSelect}
                        filterType={`New`}
                        filterDisplay={`Dropdown`}
                        filter={this.props.posts.filter}
                        setFilter={this.props.setFilter}
                        getAllTags={this.props.getAllTags}
                        tags={this.props.posts.tags}
                        fetchPosts={this.props.fetch}
                        total_pages={this.props.posts.total_pages}
                        page={this.props.posts.page}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    posts: state.blogs,
});

export default connect(mapStateToProps, {
    getAllTags,
    fetch,
    setFilter,
    selectPost,
})(Posts);
