import React, { Component } from "react";
import { connect } from "react-redux";

//Styles
import "../../styles/components/contact/contact.scss";

//Assets
import background from "../../assets/webAssets/contactBg.svg";

// Components
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import ContactForm from "./contactForm.component";

const header = {
  background: "contact",
  headingCentered: "CONTACT US",
  style: "centered",
};

class Contact extends Component {
  render() {
    return (
      <div className="contact-container">
        <Header header={header} />
        <div className="column--container clamp">
          <div className="contact-wrapper">
            <div className="contact-block">
              <div className="container-col left">
                <div className="container-text">
                  <h3>OUR ADDRESS</h3>
                  <p>Merchants House, Merchants Quay, Dublin 8.</p>
                  <h3>OUR CONTACTS</h3>
                  <p>
                    <a href="tel:+353874175253 ">Tel: 087 4175253</a>
                  </p>
                  <p>
                    <a
                      href="mailto:info@brackenrecruitment.ie"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email: info@brackenrecruitment.ie
                    </a>
                  </p>
                  <p className="company-reg">Company Reg Number: 656309</p>
                </div>
              </div>
              <div className="container-col">
                <img
                  className="bg-img"
                  src={background}
                  alt="background block"
                />
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default connect(null, {})(Contact);
