import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { MobileNavbar } from "dg-navbar";
import history from "../../history";

//Assets
import logo from "../../assets/webAssets/logo.svg";
import facebook from "../../assets/icons/facebook.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";

//Assets
import mobileNav from "../../assets/files/mobileNavbar.js";

//Styles
import "../../styles/components/navbar/navbar.scss";
import "../../styles/components/navbar/sideDrawer.scss";

class Navbar extends Component {
    render() {
        return (
            <div className="navbar">
                <div className="navbar-desktop clamp">
                    <nav className="navbar-main">
                        <ul>
                            <li>
                                <Link
                                    to="/"
                                    className="navbar-logo"
                                >
                                    <img src={logo} alt="bracken logo" />
                                </Link>
                            </li>
                            <li>
                                <NavLink exact={true} to="/">
                                    <p>Home</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/job-board" exact={true}>
                                    <p>Jobs</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" exact={true}>
                                    <p>About Us</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/blog" exact={true}>
                                    <p>Blog</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" exact={true}>
                                    <p>Contact</p>
                                </NavLink>
                            </li>
                            <li className="join-us">
                                <Link to="/contact" exact={true}>
                                    <p>JOIN US</p>
                                </Link>
                                    </li>
                            <li className="socials-container">
                                <a href="https://www.facebook.com/Bracken-Recruitment-Ltd-117027093028170/">
                                    <img src={facebook} alt="facebook logo" />
                                </a>
                                <a href="https://www.linkedin.com/company/bracken-recruitment-ltd-irl">
                                    <img src={linkedin} alt="linkedin logo" />
                                </a>
                                <a href="https://twitter.com/brackenrecltd">
                                    <img src={twitter} alt="twitter logo" />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="navbar-mobile">
                    <MobileNavbar
                        history={history}
                        logoAlt={mobileNav.logo}
                        menuIcon={mobileNav.menuIcon}
                        closeIcon={mobileNav.closeIcon}
                        sideDrawerItems={mobileNav.sideDrawerItems}
                        title={mobileNav.title}
                        socials={mobileNav.socials}
                    />
                </div>
            </div>
        );
    }
}

export default connect()(Navbar);
