import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import history from "../../history";

// Styles
import "../../styles/components/login/login.scss";

// Assets

// Components
import Navbar from "../../components/navbar/navbar.component";
import Footer from "../../components/footer/footer.component";
import LoginForm from "./loginForm.component";

// Actions
import { login } from "../../actions/login.action";
class Login extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(form) {
        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;

        this.props
            .login(form)
            .then(res => {
                localStorage.setItem(
                    `${projectName}::access_token`,
                    res.access_token
                );
                history.push("/admin/applications");
            })
            .catch(res => {
                alert(res.message);
            });
    }

    render() {
        return (
            <div className="login-container">
                <Navbar />
                <div className="form-container">
                    <LoginForm
                        onSubmit={this.onSubmit}
                    />
                </div>
                <Footer />
            </div>
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired
};

export default connect(null, { login })(Login);
