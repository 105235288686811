import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import { reducer as blogReducer } from 'redux-blogs';
import loginReducer from './login.reducer';
import postReducer from './post.reducer';
import jobsReducer from './job.reducer';
import contactReducer from './contact.reducer';
import applicationsReducer from './application.reducer';
import usersReducer from './user.reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loggedInUser: loginReducer,
    form: formReducer,
    jobs: jobsReducer,
    blogs: blogReducer,
    posts: postReducer,
    contacts: contactReducer,
    applications: applicationsReducer,
    users: usersReducer,
});

export default createRootReducer;
