import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import history from "../../../history";

// Actions
import { uploadImage, AdminEditPost, setPostLoading } from "redux-blogs";
import { updatePost } from "../../../actions/postv2.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class AdminPostEdit extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        if (
            this.props.posts &&
            this.props.posts.currentPost &&
            Object.keys(this.props.posts.currentPost).length <= 0
        ) {
            history.push(`/admin/blogs`);
        }
    }

    onSubmit(data) {
        this.props.setPostLoading(true);

        console.log("Form Submit :: Edit");

        const form = new FormData();
        let body = data;

        if (
            data.header_image &&
            typeof data.header_image !== "string" &&
            data.header_image.length > 0
        ) {
            for (let index = 0; index < data.header_image.length; index++) {
                form.append("updated_header_image", data.header_image[index]);
            }

            delete body.header_image;
        }

        form.append("post_details", JSON.stringify(body));
        console.log(form);
        this.props
            .updatePost(data._id, form)
            .then((res) => {
                swal.fire("Success!", `${res.message}`, "success");
                window.scrollTo(0, 0);
                history.push("/admin/blogs");
            })
            .catch((res) => {
                swal.fire("Couldn't save Post!", `${res.message}`, "error");
            });
    }

    render() {
        return (
            <div className="blog-container">
                <Sidebar />
                <div className="blog-title">
                    <h1>Edit Post</h1>
                </div>

                <AdminEditPost
                    onSubmit={this.onSubmit}
                    uploadImage={this.props.uploadImage}
                    post={this.props.posts.currentPost}
                    blog={this.props.posts}
                    noPostComponent={<h2>No Posts</h2>}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    posts: state.blogs,
});

export default connect(mapStateToProps, {
    updatePost,
    uploadImage,
    setPostLoading,
})(AdminPostEdit);
