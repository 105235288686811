import React from "react";
import { reduxForm } from "redux-form";

//Assets
import "../../styles/components/footer/footer.scss";

const FooterFormSuccessPage = props => {
    return (
        <div className="form-container">
            <div className="subscribe-form">
                <div className="form-success">
                    <h1>Thank You!</h1>
                    <p>You have been added to our mailing list!</p>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "subscribe", //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(FooterFormSuccessPage);
