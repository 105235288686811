import closeIcon from "../icons/close_button.svg";
import logo from "../webAssets/logo.svg";
import menuIcon from "../icons/menu_icon.svg";

import facebook from "../icons/facebook.svg";
import linkedin from "../icons/linkedin.svg";
import twitter from "../icons/twitter.svg";

const mobileNav = {
    sideDrawerItems: [
        {
            title: "Home",
            link: "/"
        },
        {
            title: "Jobs",
            link: "/job-board"
        },
        {
            title: "About Us",
            link: "/about"
        },
        {
            title: "Blog",
            link: "/blog"
        },
        {
            title: "Contact",
            link: "/contact"
        }
    ],
    socials: [
        {
            image: facebook,
            link: "https://www.facebook.com/Bracken-Recruitment-Ltd-117027093028170/"
        },
        {
            image: linkedin,
            link: "https://www.linkedin.com/company/bracken-recruitment-ltd-irl"
        },
        {
            image: twitter,
            link: "https://twitter.com/brackenrecltd"
        }
    ],
    title: "Bracken Recruitment",
    menuIcon: menuIcon,
    closeIcon: closeIcon,
    logo: logo,
};

export default mobileNav;
