import React, { Component } from 'react';

import AdminPostEdit from '../../components/admin/posts/edit.component';

class EditBlog extends Component {
    
    render() {
        return (
            <div className="container">
                <main>
                    <AdminPostEdit />
                </main>
                
            </div>
        );
    }
}

export default EditBlog;
