import React, { Component } from "react";
import { connect } from "react-redux";
import { store } from "../../store";
// Styles
import "../../styles/components/forms/searchForm.scss";

import { change, Field, reduxForm } from "redux-form";
import { dgInput, dgSelect } from "dg-forms";

import {
    getAllJobs,
    getJobs,
    setTotalItems,
    saveJobSearch,
    setPage,
    setTotalPages
} from "../../actions/job.action";

class SearchForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 8,
            total_pages: 0,
            total_items: 0,
            noSimilarJobs: false
        };
        this.resetForm = this.resetForm.bind(this);
    }

    resetForm() {
        // Clear values from fields on form
        store.dispatch(change("search", "function", ""));
        store.dispatch(change("search", "keyword", ""));
        store.dispatch(change("search", "location", ""));
        this.props.saveJobSearch("");
        this.props.getJobs(this.state.page, this.state.page_size).then(res => {
            this.setState({
                page: res.page,
                page_size: res.page_size,
                total_pages: res.total_pages,
                total_items: res.total_items
            });
            this.props.setTotalItems(this.state.total_items);
            this.props.setTotalPages(this.state.total_pages);
            this.props.setPage(this.state.page);
        });
    }

    componentDidMount() {
        this.props.getAllJobs();
    }

    render() {
        let countyOptions = [];
        if (this.props.counties && this.props.counties.length > 0) {
            countyOptions = this.props.counties.map((county, index) => (
                <option key={`county_option_${index}`} value={county}>
                    {county}
                </option>
            ));
        }
        let categoryOptions = [];
        if (this.props.categories && this.props.categories.length > 0) {
            categoryOptions = this.props.categories.map((category, index) => (
                <option key={`category_option_${index}`} value={category}>
                    {category}
                </option>
            ));
        }
        return (
            <div className="form-container">
                <form
                    className="search-form"
                    onSubmit={this.props.handleSubmit}
                >
                    <div className="search-fields">
                        <Field
                            containerClass="field-group"
                            name="keyword"
                            placeholder="KEYWORD"
                            type="text"
                            component={dgInput}
                        />
                        <Field name="function" component={dgSelect}>
                            <option value="">JOB FUNCTION</option>
                            {categoryOptions}
                        </Field>
                        <Field name="location" component={dgSelect}>
                            <option value="">JOB LOCATION</option>
                            {countyOptions}
                        </Field>

                        <div className="form-container-button">
                            <button type="submit" className="btn-main">
                                FILTER
                            </button>
                            <button
                                type="reset"
                                onClick={this.resetForm}
                                className="btn-secondary"
                            >
                                CLEAR
                            </button>
                        </div>
                    </div>
                    {this.props.type === "home" ? (
                        ""
                    ) : (
                        <div className="search-amount">
                            <p>
                                {" "}
                                Your search returned{" "}
                                {this.props.jobs.totalItems} results
                            </p>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    allJobs: state.jobs.allJobs,
    searchValues: state.jobs.searchValues,
    jobs: state.jobs
});

SearchForm = reduxForm({
    // a unique name for the form
    form: "search"
})(SearchForm);

export default connect(mapStateToProps, {
    getJobs,
    setTotalItems,
    setTotalPages,
    saveJobSearch,
    setPage,
    getAllJobs
})(SearchForm);
