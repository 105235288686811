import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Assets
import submitCV from "../../assets/webAssets/submitCV.svg";
import submitRole from "../../assets/webAssets/submitRole.svg";
import bg1 from "../../assets/webAssets/bg1.svg";
import bg2 from "../../assets/webAssets/bg2.svg";
import box1 from "../../assets/webAssets/review-box1.svg";

//Styles
import "../../styles/components/submit/submit.scss";

//Assets
import arrow from "../../assets/icons/btn-arrow.svg";

class Submit extends Component {
    render() {
        return (
            <div className="submit-container clamp">
                <div className="container-row">
                    <div className="submit-col">
                        <div className="card-container">
                            <img
                                className="box-1"
                                src={box1}
                                alt="submit your cv"
                            />
                            <img
                                className="main-image cv-img"
                                src={submitCV}
                                alt="submit your cv"
                            />
                            <NavLink
                                to="/job-board"
                                className="button-cv"
                                exact={true}
                            >
                                <button className="btn-main">
                                    VIEW JOBS
                                    <img src={arrow} alt="more about us" />
                                </button>
                            </NavLink>
                        </div>
                        <img
                            className="background-image cv-bg"
                            src={bg1}
                            alt="cv background"
                        />
                    </div>
                    <div className="submit-col">
                        <div className="card-container">
                            <img
                                className="main-image"
                                src={submitRole}
                                alt="submit a role"
                            />
                            <NavLink
                                to="/contact"
                                className="button-role"
                                exact={true}
                            >
                                <button className="btn-main ">
                                    CONTACT US
                                    <img src={arrow} alt="more about us" />
                                </button>
                            </NavLink>
                        </div>
                        <img
                            className="background-image role-bg"
                            src={bg2}
                            alt="role background"
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(null, {})(Submit);
