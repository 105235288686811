import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";
import swal from "sweetalert2";

//Styles
import "../../styles/components/header/header.scss";

// Components
import Navbar from "../../components/navbar/navbar.component";
import SearchForm from "../../components/search_form/searchForm.component";

//Actions
import {
    getJobs,
    setTotalItems,
    setTotalPages,
    setPage,
    createJobSearch,
    saveJobSearch,
} from "../../actions/job.action";

function setBackground(url) {
    // Webpack can't handle dynamic 'require' mechanism. Need to load all backgrounds then switch them here.
    let backgrounds = {
        home: require("../../assets/webAssets/home-banner.jpeg"),
        jobBoard: require("../../assets/webAssets/jobBoardHeader.svg"),
        contact: require("../../assets/webAssets/contactHeader.svg"),
        blog: require("../../assets/webAssets/blogHeader.svg"),
        about: require("../../assets/webAssets/about-bg.jpg"),
    };
    let background = backgrounds[url] || url;

    return {
        backgroundImage: "url(" + background + ")",
    };
}

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 8,
            total_pages: 0,
            total_items: 0,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.paginate = this.paginate.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    clearForm() {
        console.log("clearing form");
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        //If no values - get all
        if (!this.props.form.values) {
            this.props.saveJobSearch("");
            this.props
                .getJobs(page, page_size)
                .then((res) => {
                    this.setState({
                        page: res.page,
                        page_size: res.page_size,
                        total_pages: res.total_pages,
                        total_items: res.total_items,
                    });
                    this.props.setTotalItems(this.state.total_items);
                    this.props.setTotalPages(this.state.total_pages);
                    this.props.setPage(this.state.page);
                    history.push("/job-board/");
                })
                .catch((res) => {
                    swal.fire("Failed", `${res.message}`, "error");
                });
        } else {
            if (this.props.type === "board") {
                //set values to store, on componentdidmount of jobs board - search these values
                console.log("searching");
                this.props.saveJobSearch(this.props.form.values);
                this.props
                    .createJobSearch(0, page_size, this.props.form.values)
                    .then((res) => {
                        this.setState({
                            page: res.page,
                            page_size: res.page_size,
                            total_pages: res.total_pages,
                            total_items: res.total_items,
                        });
                        this.props.setTotalItems(this.state.total_items);
                        this.props.setTotalPages(this.state.total_pages);
                        this.props.setPage(this.state.page);
                        history.push("/job-board/");
                    })
                    .catch((res) => {
                        swal.fire("Failed", `${res.message}`, "error");
                    });
            } else if (this.props.type === "home") {
                //set values to store, on componentdidmount of jobs board - search these values

                this.props.saveJobSearch(this.props.form.values);
                history.push("/job-board/");
            }
        }
    }
    onSubmit() {
        this.paginate(this.state.page, this.state.page_size);
    }

    render() {
        let counties = [];

        // get request for all counties
        for (let index = 0; index < this.props.allJobs.length; ++index) {
            let trimmedCounty = this.props.allJobs[
                index
            ].job_details.location.trim();

            if (counties.includes(trimmedCounty)) {
                continue;
            } else {
                counties.push(this.props.allJobs[index].job_details.location);
            }
        }

        let categories = [];

        // get request for all categories
        for (let index = 0; index < this.props.allJobs.length; ++index) {
            if (
                categories.includes(
                    this.props.allJobs[index].job_details.function
                )
            ) {
                continue;
            } else {
                categories.push(this.props.allJobs[index].job_details.function);
            }
        }
        return (
            <div className="header-container">
                <Navbar />
                <div
                    className={
                        this.props.header.style
                            ? "container-header centered"
                            : "container-header"
                    }
                    style={setBackground(this.props.header.background)}
                >
                        <div className="clamp container">
                    {this.props.header.heading ? (
                        <h1 className="text-large">
                            {this.props.header.heading}
                        </h1>
                    ) : (
                        ""
                    )}
                    {this.props.header.subHeading ? (
                        <h1 className="text-medium">
                            {this.props.header.subHeading}
                        </h1>
                    ) : (
                        ""
                    )}
                    {this.props.header.headingCentered ? (
                        <h1 className="text-medium">
                            {this.props.header.headingCentered}
                        </h1>
                    ) : (
                        ""
                    )}
                    {this.props.header.searchType &&
                    this.props.header.searchType === "jobBoard" ? (
                        <SearchForm
                            clearForm={this.clearForm}
                            categories={categories}
                            counties={counties}
                            type={this.props.type}
                            onSubmit={this.onSubmit}
                        />
                    ) : (
                        ""
                    )}
                </div>
                                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.jobs.jobs,
    allJobs: state.jobs.allJobs,
    form: state.form.search,
});

export default connect(mapStateToProps, {
    createJobSearch,
    saveJobSearch,
    setTotalItems,
    setTotalPages,
    setPage,
    getJobs,
})(Header);
