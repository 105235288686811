import React, { Component } from 'react';
import { connect } from 'react-redux';

// Style
import '../../../styles/components/admin/listOptions.scss';

// Actions

class ListOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDashboardsOpen: false
        };
    }

    render() {

        return (
            <nav className="ListOptions">
                <ul className="ListOptions-nav">

                    <li className={`nav-item ${this.state.isDashboardsOpen ? `show`: ''}`}>
                        <div className="nav-link dropdown" onClick={() => this.setState({isDashboardsOpen: !this.state.isDashboardsOpen})}>
                            <svg 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="fas" 
                                data-icon="ellipsis-v" 
                                class="svg-inline--fa fa-ellipsis-v fa-w-6" 
                                role="img" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 192 512"
                            >
                                <path 
                                    fill="currentColor" 
                                    d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
                                    className="fa-primary"
                                >
                                </path>
                            </svg>
                            <div className={`dropdown-menu ${this.state.isDashboardsOpen ? `show`: ''}`}>
                                <button
                                    type="button"
                                    className="dropdown-link"
                                    onClick={this.props.onDelete}
                                >
                                    <svg 
                                        aria-hidden="true" 
                                        focusable="false" 
                                        data-prefix="fas" 
                                        data-icon="trash-alt" 
                                        class="svg-inline--fa fa-trash-alt fa-w-14" 
                                        role="img" 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 448 512"
                                    >
                                        <path 
                                            fill="currentColor" 
                                            d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"
                                            className="fa-primary"
                                        >
                                        </path>
                                    </svg>
                                    <span className="link-text">Delete</span>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        );
    }
}

export default connect(null, {  })(ListOptions);
