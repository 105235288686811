import React, { Component } from "react";
import { connect } from "react-redux";
import { dgSelect, dgUpload, dgInput, required } from "dg-forms";
import WYSIWUG_Editor from "../wysiwug/WYSIWUG_Editor";
import { DgCustomForm } from "dg-forms";
import { Field } from "redux-form";
import history from "../../../history";
import Swal from "sweetalert2";

// Styles
import "../../../styles/components/admin/admin.scss";

// Actions
import {
    getJobById,
    updateJob,
    setCurrentJob,
} from "../../../actions/job.action";

// Components
import Sidebar from "../sidebar/sidebar.admin.component";

class EditJob extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split("/");

        if (params.length >= 4) {
            this.props
                .getJobById(params[4])
                .then((res) => {
                    this.props.setCurrentJob(res.job);
                })
                .catch((res) => {
                    Swal.fire("Error", res.message, "error");
                });
        }
    }

    onSubmit(data) {
        console.log(data);

        this.props
            .updateJob(this.props.jobs.currentJob._id, data)
            .then((res) => {
                this.props.setCurrentJob({});
                Swal.fire(`Job Updated`, res.message, "success");
                history.push("/admin/jobs");
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }

    render() {
        let fields = [
            <>
                <div className="form-row">
                    <Field
                        name="job_details.title"
                        type="text"
                        component={dgInput}
                        label="Job Title"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.type"
                        labelClass="required"
                        label="Type"
                        component={dgSelect}
                        validate={[required]}
                    >
                        <option value="" selected>
                            Please Select
                        </option>
                        <option value="Full Time" selected>
                            Full Time
                        </option>
                        <option value="Part Time" selected>
                            Part Time
                        </option>
                    </Field>
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.location"
                        type="text"
                        component={dgInput}
                        label="Location"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.function"
                        type="text"
                        component={dgInput}
                        label="Job Category"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row"></div>
                <div className="form-row column">
                    <label name="benefits">Job Benefits</label>
                    <Field
                        name="job_details.benefits[0]"
                        type="text"
                        component={dgInput}
                        placeholder="Benefit 1"
                    />
                    <Field
                        name="benefits[1]"
                        type="text"
                        component={dgInput}
                        placeholder="Benefit 2"
                    />
                    <Field
                        name="benefits[2]"
                        placeholder="Benefit 3"
                        type="text"
                        component={dgInput}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.experience"
                        type="text"
                        component={dgInput}
                        label="Experience"
                        labelClass="required"
                        validate={[required]}
                    />
                    <Field
                        name="job_details.salary"
                        type="text"
                        component={dgInput}
                        label="Salary"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        name="job_details.summary"
                        type="text"
                        component={dgInput}
                        label="Summary"
                        labelClass="required"
                        validate={[required]}
                    />
                </div>
                <div className="form-row">
                    <Field
                        containerClass="field-group"
                        multiple={false}
                        label="Job Image"
                        labelClass="required"
                        name="files"
                        onChange={this.handleSelectedFile}
                        accept={".png, .jpg, .jpeg"}
                        component={dgUpload}
                    />
                </div>
                <div className="form-row column">
                    <label name="description">Job Description</label>
                    <Field
                        clearForm={true}
                        name="job_details.wysiwyg"
                        post={this.props.jobs}
                        component={WYSIWUG_Editor}
                    />
                </div>
            </>,
        ];
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="create-form-container">
                        {this.props.jobs &&
                        this.props.jobs.currentJob &&
                        this.props.jobs.currentJob.job_details ? (
                            <DgCustomForm
                                innerContainerClass=""
                                headerContainerClass="header"
                                formHeader={<h1>Edit Job</h1>}
                                buttonContainerClass="button-container"
                                fields={fields}
                                initialValues={this.props.jobs.currentJob}
                                enableReinitialize={true}
                                submitText="Edit Job"
                                submitClass="btn-main"
                                onSubmit={this.onSubmit}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.jobs,
});

export default connect(mapStateToProps, {
    getJobById,
    updateJob,
    setCurrentJob,
})(EditJob);
