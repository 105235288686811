import React from "react";

//Assets
import "../../styles/components/forms/contactFormv2.scss";
import successImage from "../../assets/webAssets/logo.svg";

const ApplicationFormSuccessPage = props => {
    return (
        <div className="form-container">
            <div className="contact-form application-form">
                <div className="form-success">
                    <h1>Thank You!</h1>
                    <h2>YOUR JOB APPLICATION IS ON IT'S WAY TO US!</h2>
                    <p>We usually reply within 24 hours</p>
                    <img className="logo" src={successImage} alt="success" />
                </div>
            </div>
        </div>
    );
};

export default ApplicationFormSuccessPage;
