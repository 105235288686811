import React, { Component } from "react";
import { connect } from "react-redux";

// Styles
import "../../styles/pages/about.scss";

// Assets
import about from "../../assets/webAssets/about.png";
import aboutBg from "../../assets/webAssets/aboutBg.svg";
import leaf from "../../assets/webAssets/fern.svg";
import karen from "../../assets/webAssets/karen.png";

// Components
import Header from "../../components/header/header.component";
import Footer from "../../components/footer/footer.component";
//import Recruiters from "../../components/recruiters/recruiters.component";

const header = {
    background: "about",
    headingCentered: "ABOUT US",
    style: true,
};

class About extends Component {
    render() {
        return (
            <div className="about-container">
                <Header header={header} />
                <div className="column-container">
                    <div className="container-row clamp">
                        <div className="col left">
                            <div className="heading-wrapper">
                                <div className="heading-container">
                                    <hr />
                                    <h1>ABOUT US</h1>
                                    <img src={leaf} alt="leaf background" />
                                </div>
                            </div>
                            <div className="recruiter-item">
                                <img
                                    src={karen}
                                    className="karen"
                                    alt="Karen Bracken"
                                />
                                <img
                                    src={aboutBg}
                                    className="img-bg"
                                    alt="about background"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-text">
                                <h3>About Us</h3>
                                <p>
                                    At Bracken Recruitment, we pride ourselves
                                    on delivering expert recruitment services in
                                    a friendly and professional way for both
                                    candidates and clients. Founded in 2019, our
                                    fast-growing agency has delivered on our
                                    promise to provide a superior quality of
                                    service and support our customers in
                                    ‘Finding the Perfect Fit’ when looking for a
                                    job or hiring for one. When we work with a
                                    company to source their new team member, we
                                    focus on ensuring that we have vetted all
                                    our candidates and that there are no
                                    “surprises” that can be found in
                                    conveyer-style recruiting. We also keep in
                                    touch with our candidates beyond their start
                                    date to ensure the on-boarding process runs
                                    as smoothly as possible. By providing our
                                    candidates and clients with an unmatched
                                    level of care and attention, whilst
                                    supporting their needs on an ongoing basis,
                                    we develop lasting relationships where we
                                    are partners with them on their journeys.
                                    That is why many of our clients recruit
                                    exclusively through us as their trusted
                                    recruitment partner.
                                </p>
                                <h3>Our Mission</h3>
                                <p>
                                    {" "}
                                    FINDING THE PERFECT FIT between top talent
                                    and top companies because we want to ensure
                                    long-lasting success for our customers.
                                </p>
                                <h3> Our Company Values</h3>
                                <p>
                                    {" "}
                                    <h4>Integrity</h4>
                                    <li>
                                        We value the honest, genuine and
                                        long-lasting relationships we have built
                                        with our customers and believe that is
                                        why they continue to trust us to get the
                                        job done.
                                    </li>
                                    <h4>Passion</h4>
                                    <li>
                                        Our team thrives on the work we do and
                                        cares deeply that we make a positive
                                        difference in the lives of our
                                        customers.
                                    </li>
                                    <h4>Excellence</h4>
                                    <li>
                                        In our team, we pride ourselves with our
                                        quality of service because our customers
                                        success is our success.
                                    </li>
                                    <h4>Customer Focus</h4>
                                    <li>
                                        Our team focuses on continuous, open
                                        communication with our customers because
                                        we believe that thoroughly understanding
                                        their needs enables us to provide them
                                        with the expert, reliable and supportive
                                        services they know and trust.
                                    </li>
                                </p>
                                <h3>Our Vision</h3>
                                <p>
                                    {" "}
                                    As we grow, our vision is to become the
                                    natural, trusted choice of recruitment
                                    partner for our customers because we offer
                                    more, achieve greater results, and genuinely
                                    care about their success.
                                </p>
                                <p>
                                    Bracken Recruitment was founded in 2019 by
                                    Karen Bracken, an Irish recruiter with over
                                    20 years’ experience connecting employment
                                    seekers and businesses. With broad
                                    experience ranging from Finance, Management
                                    and Recruitment, Karen has a strong
                                    commercial acumen and is involved in an
                                    advisory capacity and board member of a
                                    number of organisations. Focusing mainly on
                                    Dublin and the surrounding areas, Karen has
                                    helped build entire teams for her clients.
                                </p>
                                <p>
                                    Are you a job seeker or employer? Get in
                                    touch with us today.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container-row clamp bottom">
                        <div className="image-container">
                            <img
                                src={about}
                                className="img-large"
                                alt="about us"
                            />
                            <img
                                src={aboutBg}
                                className="img-bg"
                                alt="about background"
                            />
                        </div>
                    </div>
                </div>
                {/*<Recruiters />*/}
                <Footer />
            </div>
        );
    }
}
export default connect(null, {})(About);
