import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Assets
import more from "../../assets/webAssets/more.png";
import arrow from "../../assets/icons/btn-arrow.svg";
import leaf from "../../assets/webAssets/fern.svg";

//Styles
import "../../styles/components/services/services.scss";

class Services extends Component {
    render() {
        return (
            <div className="services-container clamp" id="services">
                <div className="heading-wrapper">
                    <div className="heading-container">
                        <div className="border-style" />
                        <h1>
                            Our <br />
                            Services
                        <img src={leaf} alt="leaf background" />
                        </h1>
                    </div>
                    <div className="heading-text">
                        <p>
                            At Bracken Recruitment we are committed to
                            connecting the right talent with the right
                            organisation and vice versa.
                        </p>
                        <p>
                            We provide a personal service to both candidates and
                            organisations. We aim to understand your business
                            requirements to ensure we make a quality
                            introduction.
                        </p>
                        <div className="container">
                            <h3>Candidates</h3>
                            <p>
                                Talk to us about your career ambitions. Hear
                                about our exclusive opportunities that are not
                                advertised anywhere else. Our team of experts
                                are here to assist you at every stage of the
                                recruitment process. We will give you CV, Career
                                and Salary advice and we will coach you to
                                succeed at interview.
                            </p>
                        </div>
                        <div className="container">
                            <h3>Clients</h3>
                            <p>
                                We offer a full recruitment service, whether you
                                are looking for 1 person or a new team we have a
                                solution for you. We are a dedicated team of
                                experts who will save you time and money whilst
                                finding the right talent for your organisation
                                leaving you to get on with more pressing aspects
                                of your day to day business.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="our-services">
                    <div className="col">
                        <div className="row">
                            {this.props.servicesTop.map((service, index) => (
                                <div key={`${index}`} className="service-card">
                                    <div className="imgWrap">
                                        <img
                                            src={service.image}
                                            alt="service"
                                        />
                                    </div>
                                    <p>{service.description}</p>
                                    <div className="card-label">
                                        {service.label}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            {this.props.servicesBottom.map((service, index) => (
                                <div key={`${index}`} className="service-card">
                                    <div className="imgWrap">
                                        <img
                                            src={service.image}
                                            alt="service"
                                        />
                                    </div>
                                    <p>{service.description}</p>
                                    <div className="card-label">
                                        {service.label}
                                    </div>
                                </div>
                            ))}
                            <div className="more-card">
                                <div className="imgWrap">
                                    <img src={more} alt="More about us" />
                                    <NavLink to={"/about"} exact={true}>
                                        <button className="btn-main">
                                            MORE ABOUT US <img src={arrow} alt="more about us" />
                                        </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(null, {})(Services);
