import {
    GET_JOBS_FAILED,
    GET_JOBS_SUCCESS,
    GET_ALL_JOBS,
    SET_IS_JOB_LOADING,
    FETCH_JOB,
    SET_TOTAL_ITEMS,
    SET_TOTAL_PAGES,
    SET_PAGE,
    SET_SEARCH_VALUES,
    DELETE_JOB,
    CREATE_JOB,
    SET_CURRENT_JOB
} from "./types";
import { getAccessToken } from "./login.action";

export const getJobs = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_JOBS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const deleteJob = jobId => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/delete/${jobId}`,
            {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    access_token: token
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: DELETE_JOB,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};



export const getJobById = id => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_JOB_LOADING,
            payload: true
        });

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/jobs/get/${id}`, {
            method: "GET",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: FETCH_JOB,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: FETCH_JOB,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const getJobBySearch = keyword => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_JOB_LOADING,
            payload: true
        });

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/title/?job_details.title=${keyword}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_JOBS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const getJobByLocation = keyword => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_JOB_LOADING,
            payload: true
        });

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/location/?job_details.location=${keyword}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_JOBS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const getJobByCategory = keyword => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_JOB_LOADING,
            payload: true
        });

        let category = encodeURIComponent(keyword);

        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/search/?job_details.function=${category}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_JOBS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const createJobSearch = (page, page_size, formData) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_JOB_LOADING,
            payload: true
        });
        let category = encodeURIComponent(formData.function);
        fetch(
            `${
                process.env.REACT_APP_API_URL
            }/api/v1/jobs/search/?page=${page}&page_size=${page_size}&${
                formData.keyword ? `job_details.title=${formData.keyword}&` : ""
            }${formData.function ? `job_details.function=${category}&` : ""}${
                formData.location
                    ? `job_details.location=${formData.location}`
                    : ""
            }`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_JOBS_SUCCESS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};

export const saveJobSearch = formData => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_SEARCH_VALUES,
            payload: formData
        });
        //let category = encodeURIComponent(formData.function);
    });
};

export const createJob = data => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/jobs/create`, {
            method: "POST",
            body: data
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_JOB,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

export const updateJob = (jobId, data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        let token = getAccessToken();

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/jobs/update/${jobId}`, {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                access_token: token
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(post => {
                dispatch({
                    type: CREATE_JOB,
                    payload: post
                });

                if (!post.ok) {
                    reject(post);
                } else {
                    resolve(post);
                }
            });
    });
};

// For categories
export const getAllJobs = (page, page_size) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/jobs/?page=${page}&page_size=${page_size}`,
            {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            }
        )
            .then(res => res.json())
            .then(post => {
                if (!post.ok) {
                    dispatch({
                        type: GET_JOBS_FAILED,
                        payload: post
                    });

                    reject(post);
                } else {
                    dispatch({
                        type: GET_ALL_JOBS,
                        payload: post
                    });

                    resolve(post);
                }
            });
    });
};
export const setCurrentJob = data => dispatch => {
    dispatch({
        type: SET_CURRENT_JOB,
        payload: data
    });
};
export const setTotalItems = data => dispatch => {
    dispatch({
        type: SET_TOTAL_ITEMS,
        payload: data
    });
};
export const setPage = data => dispatch => {
    dispatch({
        type: SET_PAGE,
        payload: data
    });
};
export const setTotalPages = data => dispatch => {
    dispatch({
        type: SET_TOTAL_PAGES,
        payload: data
    });
};
