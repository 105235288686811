export const FETCH_POSTS = 'FETCH_POSTS';
export const DELETE_POST = 'DELETE_POST';
export const NEW_POST = 'NEW_POST';
export const CREATE_POST = 'CREATE_POST';
export const SELECT_POST = 'SELECT_POST';
export const CLEAR_POST_FORM = 'CLEAR_POST_FORM';
export const SET_UPLOADS = 'SET_UPLOADS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export const GET_ALL_BLOGS = 'GET_ALL_BLOGS';
export const GET_BLOGS_FAILED = 'GET_BLOGS_FAILED';
export const GET_POSTS_FAILED = 'GET_POSTS_FAILED';
export const LOGOUT = 'LOGOUT';
export const CONTACT_SUBMIT_SUCCESS = 'CONTACT_SUBMIT_SUCCESS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const CONTACT_SUBMIT_FAILED = 'CONTACT_SUBMIT_FAILED';
export const APPLICATION_SUBMIT = 'APPLICATION_SUBMIT';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const SUBSCRIBE_SUBMIT_FAILED = 'SUBSCRIBE_SUBMIT_FAILED';
export const SUBSCRIBE_SUBMIT_SUCCESS = 'SUBSCRIBE_SUBMIT_SUCCESS';
export const APPLICATION_SUBMIT_FAILED = 'APPLICATION_SUBMIT_FAILED';
export const APPLICATION_SUBMIT_SUCCESS = 'APPLICATION_SUBMIT_SUCCESS';
export const GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const GET_JOBS_FAILED = 'GET_JOBS_FAILED';
export const SET_IS_JOB_LOADING = 'SET_IS_JOB_LOADING';
export const FETCH_JOB = 'FETCH_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const CREATE_JOB = 'CREATE_JOB';
export const SET_CURRENT_JOB = 'SET_CURRENT_JOB';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_APPLICATIONS = 'GET_APPLICATIONS';
export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const SET_SEARCH_VALUES = 'SET_SEARCH_VALUES';
export const SET_TOTAL_ITEMS = 'SET_TOTAL_ITEMS';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_PAGE = 'SET_PAGE';


//Users
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USERS = 'GET_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
