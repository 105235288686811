import React from "react";
import "./styles/App.scss";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import ProtectedRoute from "./components/protectedRoute.component";
import { store } from "./store";
import history from "./history";

//Library styles
import "redux-blogs/dist/index.css";
import "dg-pagination/dist/index.css";
import "dg-blocks/dist/index.css";

// Components
import Home from "./components/home/home.component";
import JobBoard from "./components/job_board/jobBoard.component";
import JobDetails from "./components/job_details/jobDetails.component";
import Apply from "./components/apply_now/apply.component";
import About from "./components/about/about.component";
import Contact from "./components/contact/contact.component";
import Login from "./components/login/login.component";
import Policy from "./components/policy/policy.component.js";
import ScrollToTop from "./scrollToTop";

// User Facing Blog
import Post from "./containers/blog/post.component";
import Posts from "./containers/blog/posts.component";

// Protected Routes

//Admin - Users
import AdminUserList from "./pages/admin/users/adminUserList.component";
import AdminCreateUser from "./pages/admin/users/create.component";
import AdminUpdateUser from "./pages/admin/users/update.component";

// Admin - Blogs
import BlogManagement from "./pages/blogManagement/blogManagement";
import CreateBlog from "./pages/blogManagement/createBlog.component";
import EditBlog from "./pages/blogManagement/editBlog.component";

// Admin - Jobs
import JobsList from "./components/admin/jobs/list.component";
import CreateJob from "./components/admin/jobs/create.component";
import EditJob from "./components/admin/jobs/edit.component";
import ViewJob from "./components/admin/jobs/view.component";

// Admin - Contacts
import ContactList from "./components/admin/contact/list.component";
import ViewContact from "./components/admin/contact/view.component";

// Admin - Applications
import ApplicationList from "./components/admin/applications/list.component";
import ViewApplication from "./components/admin/applications/view.component";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/apply/:id" exact component={Apply} />
                    <Route path="/about" exact component={About} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/job-board" exact component={JobBoard} />
                    <Route path="/job/:id" exact component={JobDetails} />
                    <Route path="/blog" exact component={Posts} />
                    <Route path="/blog/post/:id" exact component={Post} />
                    <Route path="/admin" exact component={Login} />
                    <Route path="/policy" exact component={Policy} />

                    <ProtectedRoute
                        path="/admin/users"
                        exact
                        component={AdminUserList}
                    />
                    <ProtectedRoute
                        path="/admin/users/create"
                        exact
                        component={AdminCreateUser}
                    />
                    <ProtectedRoute
                        path="/admin/users/update/:id"
                        exact
                        component={AdminUpdateUser}
                    />

                    <ProtectedRoute
                        path="/admin/blogs"
                        exact
                        component={BlogManagement}
                    />
                    <ProtectedRoute
                        path="/admin/blogs/create"
                        exact
                        component={CreateBlog}
                    />
                    <ProtectedRoute
                        path="/admin/blogs/edit/:id"
                        exact
                        component={EditBlog}
                    />
                    <ProtectedRoute
                        path="/admin/jobs"
                        exact
                        component={JobsList}
                    />
                    <ProtectedRoute
                        path="/admin/job/create"
                        exact
                        component={CreateJob}
                    />
                    <ProtectedRoute
                        path="/admin/job/edit/:id"
                        exact
                        component={EditJob}
                    />
                    <ProtectedRoute
                        path="/admin/job/:id"
                        exact
                        component={ViewJob}
                    />
                    <ProtectedRoute
                        path="/admin/contacts"
                        exact
                        component={ContactList}
                    />
                    <ProtectedRoute
                        path="/admin/contact/:id"
                        exact
                        component={ViewContact}
                    />
                    <ProtectedRoute
                        path="/admin/applications"
                        exact
                        component={ApplicationList}
                    />
                    <ProtectedRoute
                        path="/admin/application/:id"
                        exact
                        component={ViewApplication}
                    />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
