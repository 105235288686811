import React, { Component } from "react";
import { connect } from "react-redux";

//Assets

//Styles
import "../../styles/components/job_details/jobDetails.scss";

// Components
import Header from "../../components/header/header.component";
import Job from "./job.component";
import JobResults from "../job_results/jobResults.component";
import Footer from "../../components/footer/footer.component";

const header = {
    background: "jobBoard",
    style: true,
    searchType: "home",
};

class JobDetails extends Component {
    render() {
        return (
            <div className="jobDetails-container">
                <Header header={header} />
                <div className="column-container">
                    <Job location={this.props.location} />
                    {this.props.currentJob &&
                    this.props.currentJob.job_details &&
                    this.props.currentJob.job_details.function &&
                    this.props.currentJob.job_details.function !== "" ? (
                        <JobResults
                            type="category"
                            category={
                                this.props.currentJob.job_details.function
                            }
                            header={true}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    jobs: state.jobs.jobs,
    currentJob: state.jobs.currentJob,
});

export default connect(mapStateToProps, {})(JobDetails);
