import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

//Assets
import logo from "../../assets/webAssets/logo.svg";
import facebook from "../../assets/icons/facebook.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";

//Components
import FooterForm from "./footerForm.component";

//Styles
import "../../styles/components/footer/footer.scss";

class Footer extends Component {
    render() {
        return (
            <footer className="footer-container">
                <div className="footer-top">
                    <div className="footer-col item-left">
                        <div className="footer-main">
                            <h3>SUBSCRIBE TO JOB ALERTS</h3>
                            <p>
                                Enter your email below to be added to our email
                                list and receive regular updates on new job
                                opportunities!
                            </p>
                            <FooterForm />
                        </div>
                    </div>
                    <div className="footer-col item-right"></div>
                </div>
                <div className="foot-bottom">
                    <div className="container-row">
                        <div className="footer-col footer-logo">
                            <img src={logo} alt="bracken logo" />
                        </div>
                        <div className="footer-col">
                            <h2>INFORMATION</h2>
                            <NavHashLink smooth to="/#services">
                                Services
                            </NavHashLink>
                            <NavLink to="/blog">Blog</NavLink>
                            <NavLink to="/policy">Privacy Policy</NavLink>
                        </div>
                        <div className="footer-col">
                            <h2>ABOUT US</h2>
                            <NavLink to="/about">About</NavLink>
                            <NavLink to="/job-board">Jobs</NavLink>
                            <NavLink to="/contact">Location</NavLink>
                        </div>
                        <div className="footer-col">
                            <h2>STAY CONNECTED</h2>
                            <li className="socials-container">
                                <a href="https://www.facebook.com/Bracken-Recruitment-Ltd-117027093028170/">
                                    <img src={facebook} alt="facebook logo" />
                                </a>
                                <a href="https://www.linkedin.com/company/bracken-recruitment-ltd-irl">
                                    <img src={linkedin} alt="linkedin logo" />
                                </a>
                                <a href="https://twitter.com/brackenrecltd">
                                    <img src={twitter} alt="twitter logo" />
                                </a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="footer-disclaimer">
                    <p>&copy; 2020 Bracken Recruitment. All Rights Reserved.</p>{" "}
                    <a href="https://digigrow.ie/">
                        Website designed and built by <strong>Digigrow</strong>
                    </a>{" "}
                </div>
            </footer>
        );
    }
}

export default connect(null, {})(Footer);
