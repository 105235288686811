import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";
import { NavLink } from "react-router-dom";

// Actions
import { setFilter, fetch, CardAlt, selectPost } from "redux-blogs";

//Styles
import "../../styles/components/blog/blogLatest.scss";

//Assets
import more from "../../assets/webAssets/more.png";
import arrow from "../../assets/icons/btn-arrow.svg";
import leaf from "../../assets/webAssets/fern.svg";

class Blog extends Component {
    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        this.props.setFilter({ status: "Published" }).then(data => {
            this.props
                .fetch(2, 0, this.props.posts.filter)
                .then(data => {
                    console.log(data);
                })
                .catch(data => {
                    console.log(data);
                    alert("Failed");
                });
        });
    }

    onSelect(post) {
        this.props.selectPost(post).then(data => {
            window.scrollTo(0, 0);
            history.push(`/blog/post/${post._id}`);
        });
    }
    render() {
        return (
            <div className="blogLatest-container clamp">
                <div className="heading-wrapper">
                    <div className="heading-container">
                        <hr />
                        <h1>LATEST FROM OUR BLOG</h1>
                        <img src={leaf} alt="leaf background" />
                    </div>
                </div>
                <div className="container-row">
                    {this.props.posts.items &&
                    this.props.posts.items.length > 0 ? (
                        this.props.posts.items
                            .slice(0, 2)
                            .map((item, index) =>
                                this.props.currentPost._id !== item._id ? (
                                    <CardAlt
                                        post={item}
                                        cardClass={"card"}
                                        onSelect={this.onSelect}
                                        postOnSelect={this.onSelect}
                                        showDate={true}
                                        showSummary={false}
                                        fetchPosts={this.props.fetch}
                                    />
                                ) : (
                                    ""
                                )
                            )
                    ) : (
                        <div className="error-message">
                            <h1>We're Sorry!</h1>
                            <p>
                                There doesn't seem to be any blog posts at the moment.
                            </p>
                        </div>
                    )}
                    <div className="blog-card more-blog-card card">
                        <div className="img">
                            <img src={more} alt="view more posts" />
                            <NavLink to={"/blog"} exact={true}>
                                <button className="btn-main">
                                    VIEW MORE{" "}
                                    <img src={arrow} alt="more about us" />
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.blogs,
    currentPost: state.blogs.currentPost
});

export default connect(mapStateToProps, { setFilter, fetch, selectPost })(Blog);
