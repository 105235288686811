import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

//Styles
import "../../styles/components/header/header.scss";
import "../../styles/components/apply_now/apply.scss";

//Assets
import share from "../../assets/icons/share.svg";

// Components
import Header from "../../components/header/header.component";
import ApplicationForm from "./applicationForm.component";
import Footer from "../../components/footer/footer.component";

const header = {
    background: "jobBoard",
    style: true,
    headingCentered: "APPLY NOW",
    searchType: "home",
};

class Apply extends Component {
    render() {
        return (
            <div className="apply-container">
                <Header header={header} />
                <div className="column-container">
                    <div className="container-row clamp">
                        <div className="container-column left">
                            <ApplicationForm />
                        </div>
                        <div className="container-column right">
                            <NavLink exact={true} to="/job-board">
                                <button className="btn-share">
                                    Back To All Positions
                                </button>
                            </NavLink>
                            <a
                                href="https://www.facebook.com/sharer/sharer.php?u=example.org"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="btn-share">
                                    <img src={share} alt="share job" />
                                    Share Job Position{" "}
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default connect(null, {})(Apply);
