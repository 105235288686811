import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "./WYSIWUG.scss";

class WYSIWUG_Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            time: 0,
            start: 0,
        };
        this.startTimer = this.startTimer.bind(this);

        this.props.input.onChange(
            draftToHtml(
                convertToRaw(this.state.editorState.getCurrentContent())
            )
        );
    }

    componentDidMount() {
        this.startTimer();
        console.log(this.props.clearForm);
        if (this.props.clearForm) {
            let post =
                this.props.post &&
                this.props.post.currentJob &&
                this.props.post.currentJob.job_details &&
                this.props.post.currentJob.job_details.wysiwyg &&
                this.props.post.currentJob.job_details.wysiwyg !== ""
                    ? this.props.post.currentJob.job_details.wysiwyg
                    : "";

            console.log("post", post);
            const contentBlock = htmlToDraft(post);

            console.log("props", this.props);
            console.log("content block: ", contentBlock);

            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);

                console.log(editorState);
                this.setState({ editorState: editorState });
            }
        }
    }

    componentDidUpdate() {
        if (this.state.time === 100) {
            this.focus();
        }
    }

    focus() {
        document
            .getElementsByClassName("public-DraftEditor-content")[0]
            .focus();
        console.log("focused");
    }

    startTimer() {
        this.timer = setInterval(
            () =>
                this.setState({
                    time: this.state.time + 10,
                }),
            10
        );
    }

    onEditorStateChange = (editorState) => {
        const {
            input: { onChange, value },
        } = this.props;

        const newValue = draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
        );

        console.log(`Value :: ${value}`);
        console.log(`NewValue :: ${newValue}`);
        if (value !== newValue) {
            onChange(newValue);
        }

        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;

        return (
            <div className="WYSIWUG_Editor">
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        image: {
                            previewImage: true,
                        },
                    }}
                />
            </div>
        );
    }
}

export default WYSIWUG_Editor;
