import React from "react";

//Assets
import "../../styles/components/footer/footer.scss";

import { Field, reduxForm } from "redux-form";
import { dgInput, required, email } from "dg-forms";

let SubscribeForm = props => {
    return (
        <div className="footer-form-container">
            <form className="subscribe-form" onSubmit={props.handleSubmit}>
                <Field
                    name="email"
                    type="text"
                    placeholder="Input your email here"
                    component={dgInput}
                    validate={[required, email]}
                />
                <div className="form-container-button">
                    <button type="submit" className="btn-main">
                        SUBSCRIBE
                    </button>
                </div>
            </form>
        </div>
    );
};

SubscribeForm = reduxForm({
    // a unique name for the form
    form: "subscribe"
})(SubscribeForm);

export default SubscribeForm;
