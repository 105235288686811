import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import history from "../../../history";
import { Pagination } from "dg-pagination";

// Styles
import "../../../styles/components/admin/admin.scss";
import "../../../styles/components/admin/list.scss";

// // Components
import Sidebar from "../../../components/admin/sidebar/sidebar.admin.component";
import ListOptions from "../../../components/admin/listOptions/listOptions";

// // Actions
import { deleteUser, getUsers } from "../../../actions/user.action";

class AdminUserList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            page_size: 10,
            total_pages: 0,
            total_items: 0,
        };

        this.paginate = this.paginate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props
            .getUsers(page, page_size)
            .then((res) => {
                this.setState({
                    page: res.page,
                    page_size: res.page_size,
                    total_pages: res.total_pages,
                    total_items: res.total_items,
                });
            })
            .catch((res) => {
                Swal.fire(`Error`, res.message, "error");
            });
    }
    onDelete(event, value) {
        event.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.deleteUser(value._id);
                Swal.fire("Deleted!", "User has been deleted.", "success");
                this.componentDidMount();
            }
        });
    }
    onEdit(event, value) {
        event.preventDefault();
        history.push(`/admin/users/update/${value._id}`);
    }
    render() {
        let rows = [];

        if (
            this.props.users &&
            this.props.users.users &&
            this.props.users.users.length > 0
        ) {
            rows = this.props.users.users.map((value, index) => (
                <tr>
                    <td>{value.email}</td>
                    <td>
                        {new Date(value.createdAt).toLocaleDateString()}{" "}
                        {new Date(value.createdAt).toLocaleTimeString()}
                    </td>
                    <td>
                        <ListOptions
                            onEdit={(e) => this.onEdit(e, value)}
                            onDelete={(e) => this.onDelete(e, value)}
                        />
                    </td>
                </tr>
            ));
        } else {
            rows = [
                <tr>
                    <td colSpan="5">No Users Available ...</td>
                </tr>,
            ];
        }
        return (
            <div className="admin-container">
                <Sidebar />
                <main>
                    <div className="List-Container">
                        <div className="item head">
                            <h1>All Users</h1>
                        </div>

                        <div className="create-container">
                            <button
                                className="btn-primary"
                                onClick={() =>
                                    history.push(`/admin/users/create/`)
                                }
                            >
                                + Create User
                            </button>
                        </div>
                        <div className="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Created On</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </table>
                            <Pagination
                                type="Standard"
                                paginate={this.paginate}
                                page_size={this.state.page_size}
                                page={this.state.page}
                                total_pages={this.state.total_pages}
                                total_items={this.state.total_items}
                            />
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.users,
    user: state.loggedInUser,
});

export default connect(mapStateToProps, {
    getUsers,
    deleteUser,
})(AdminUserList);
